import { Rect } from "react-konva";
import { useEffect, useRef } from "react";

export function ChargingBar({ isCharging }) {
  const chargingBarRef = useRef(null);
  useEffect(() => {
    changeSize(isCharging);
  }, [isCharging]);

  const changeSize = (isCharging) => {
    const node = chargingBarRef.current;
    if (node) {
      if (isCharging === false) {
        node.to({
          width: 0,
          duration: 0,
        });
        return;
      } else {
        node.to({
          width: 250,
          duration: 3,
        });
      }
    }
  };

  return (
    <Rect
      width={10}
      height={20}
      y={64}
      x={50}
      ref={chargingBarRef}
      fill="orange"
    ></Rect>
  );
}
