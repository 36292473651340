import { Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";

export function Print() {
  const infoBoxProps = useInfoBox({
    title: "Print",
    description:
      "This feature is not essential for defibrillator operation and will not be covered in this module",
  });

  return (
    <ClickableGroup {...infoBoxProps} x={52} y={425}>
      <Rect width={85} height={32} stroke={OVERVIEW_OUTLINE_STROKE_COLOR} />
    </ClickableGroup>
  );
}
