import { Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";

export function Pause({ isOverview }) {
  const infoBoxProps = useInfoBox({
    title: "Pause",
    description:
      "Pressing the PAUSE button allows you to check the patient's underlying rhythm. When the PAUSE button is held, the Lifepak paces at 25% of the set rate.<br><br>  In demand pacing mode, when the patient's underlying rate is greater than 25% of the paced rate, there will be no demand for pacing and you will not see any vertical pacing spikes.",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      x={653}
      y={445}
      onClick={() => {
        if (isOverview) return;
      }}
    >
      <Rect
        width={60}
        height={35}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
    </ClickableGroup>
  );
}
