import { Link } from "react-router-dom";

export function HomePage() {
  return (
    <div>
      <p>Welcome to the Patient Project. </p>
      <ul>
        <li>
          <Link to="/lifepak">Lifepak PALS Simulator</Link>
        </li>
        <li>
          <Link to="/Zol">Zol PALS Simulator</Link>
        </li>
      </ul>
    </div>
  );
}
