import { Shock } from "./Shock";
import { Analyze } from "./Analyze";
import { Sync } from "./Sync";
import { Pacer } from "./Pacer";
import { RateDown } from "./RateDown";
import { RateUp } from "./RateUp";
import { CurrentDown } from "./CurrentDown";
import { CurrentUp } from "./CurrentUp";
import { Pause } from "./Pause";
import { Lead } from "./Lead";
import { SpeedDial } from "./SpeedDial";
import { ECGCablePort } from "./ECGCablePort";
import { TherapyCablePort } from "./TherapyCablePort";
import { OverviewOnly } from "./OverviewOnly";
import { Charge } from "./Charge";
import { OnButton } from "./OnButton";
import { EnergySelectUp } from "./EnergySelectUp";
import { EnergySelectDown } from "./EnergySelectDown";

export function DefibrillatorButtons({ isOverview }) {
  return (
    <>
      <EnergySelectDown isOverview={isOverview} />
      <EnergySelectUp isOverview={isOverview} />

      <OnButton isOverview={isOverview} />
      <Charge isOverview={isOverview} />
      <Shock isOverview={isOverview} />

      <Analyze isOverview={isOverview} />

      <Sync isOverview={isOverview} />
      <Pacer isOverview={isOverview} />

      <RateDown isOverview={isOverview} />
      <RateUp isOverview={isOverview} />

      <CurrentDown isOverview={isOverview} />
      <CurrentUp isOverview={isOverview} />
      <Pause isOverview={isOverview} />
      <Lead isOverview={isOverview} />
      <SpeedDial isOverview={isOverview} />
      <ECGCablePort isOverview={isOverview} />
      <TherapyCablePort isOverview={isOverview} />

      {isOverview && <OverviewOnly />}
    </>
  );
}
