import { Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";

export function TherapyCablePort({ isOverview }) {
  const infoBoxProps = useInfoBox({
    title: "Therapy Cable Attachment Port",
    description:
      " Site of attachment for EITHER the therapy cable or the machine's hard paddles.",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      x={545}
      y={560}
      onClick={() => {
        if (isOverview) return;
      }}
    >
      <Rect
        width={65}
        height={65}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
    </ClickableGroup>
  );
}
