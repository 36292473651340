import { Text } from "react-konva";
import { useEffect, useState } from "react";

export function TimeDisplay() {
  const formatTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };
  // useState hook to keep track of the current time
  const [currentTime, setCurrentTime] = useState(formatTime());

  // useEffect hook to set up an interval
  useEffect(() => {
    // Set up an interval to update the time every second
    const timerId = setInterval(() => {
      setCurrentTime(formatTime());
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(timerId);
    };
  }, []);

  // Render the current time
  return <Text x={145} y={20} text={currentTime} fill="white" />;
}
