import { createContext, useState, useEffect, useContext } from "react";
import { Defibrillator } from "./model/defibrillator";

// Define the shape of the context
interface DefibContextType {
  defib: Defibrillator | null;
  defibState: any; // Replace 'any' with the actual type of defibState
  setDefibState: React.Dispatch<React.SetStateAction<any>>; // Same here for the type
}

// Create the context with an initial value
const DefibContext = createContext<DefibContextType>({
  defib: null,
  defibState: Defibrillator.defaultReturnState(), // Assuming this is the correct initial state
  setDefibState: () => {},
});
export const useDefib = () => useContext(DefibContext);

export const DefibProvider = ({ children, scene }) => {
  const [defib, setDefib] = useState(null);
  const [defibState, setDefibState] = useState(
    Defibrillator.defaultReturnState()
  );

  useEffect(() => {
    const d = new Defibrillator();
    setDefib(d);
    setDefibState(d.calculateReturnState());

    const handleStateChange = () => {
      setDefibState(d.returnState);
    };

    window.addEventListener("defibStateChange", handleStateChange);

    return () => {
      d.destroy();
      window.removeEventListener("defibStateChange", handleStateChange);
    };
  }, [scene]);

  return (
    <DefibContext.Provider value={{ defib, defibState, setDefibState }}>
      {children}
    </DefibContext.Provider>
  );
};
