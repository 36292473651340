import { useState } from "react";
import { ClickableGroup, KonvaImage } from "../../../UIComponents";
import { useInfoBox } from "../../../App";

export function ECGCable({}) {
  const [position, setPosition] = useState({
    x: -80,
    y: 530,
  });
  const infoBoxProps = useInfoBox({
    title: "ECG Cable",
    description:
      "Lifepak's own ECG leads connect to the defibrillator when the ECG cable is plugged in to the ECG cable port. Use your mouse to drag and drop the end of the cable onto the ECG port.",
  });
  return (
    <ClickableGroup
      {...infoBoxProps}
      draggable={true}
      onDragMove={(e: any) => {
        const pos = e.target.getAbsolutePosition();
        setPosition({ x: pos.x, y: pos.y });
      }}
      x={position.x}
      y={position.y}
      scaleX={0.6}
      scaleY={0.6}
    >
      <KonvaImage src="assets/EKGConnector.png" />
    </ClickableGroup>
  );
}
