import { Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";
import { useDefib } from "@/DefibContextManager";

export function RateUp({ isOverview }) {
  const { defib } = useDefib();
  const infoBoxProps = useInfoBox({
    title: "Rate Up",
    description:
      "Once the PACER button is engaged, press the up or down arrows to select the (paced) heart rate you wish the patient to have.",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      x={680}
      y={375}
      onClick={() => {
        if (isOverview) return;
        defib.rateUp();
      }}
    >
      <Rect
        width={40}
        height={35}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
    </ClickableGroup>
  );
}
