export enum TutorialCase1Prompts {
  // tutorial case 1 prompts
  INIT_ASSESS = "First assess the patient's condition by clicking 'Assess'.",
  START_CPR = `The patient is not breathing and has no pulse. The most important thing to do first is to initiate CPR immediately!
  
  Use your mouse or touchpad to press "Start CPR"`,
  TURN_ON = 'Next you will need to connect the patient to the defibrillator to display and assess the cardiac rhythm. Turn on the machine by pushing the "ON" button.',
  REMOVE_TEST_PLUG = "Use your mouse to drag away the black test plug and expose the grey adapter on the therapy cable.",
  CONNECT_ELECTRODES = 'The machine prompts you to "Connect Electrodes". The therapy pads are the electrodes. <br><br>Use your mouse to connect the grey adapter on the therapy cable to the grey adapter on the defibrillator pads. Move the pads into proper position on the patient. The white pad goes on the patient\'s upper right chest and the "heart" pad should be positioned just lateral to the apex of the patient\'s heart.',
  LOOK_AT_VFIB_RHYTHM = 'Look carefully at the rhythm. There are no definable QRS complexes. This disorganized electrical activity is characteristic of ventricular fibrillation. You will need to defibrillate. <br><br>First you will need to push "Energy Select" and use the arrows to select the appropriate energy dose. The minimum dose for the first shock in an adult is 200J.',
  CHARGE = "You are now ready to hit the CHARGE button. Notice that the audible tone is much more prolonged when the machine charges to larger energy doses.",
  STOP_CPR_AND_SHOCK = "Click STOP CPR and press the shock button. <br><br>Once you select the energy dose and prepare the machine to shock, you must deliver the shock within 60 seconds or the stored energy will be removed by the machine. If this happens you will need to redose, recharge, and try again.",
  RESTART_CPR = 'Restart CPR immediately! Using your mouse or touchpad, click "Start CPR". Continue CPR for 2 minutes before assessing the rhythm and determining whether the patient has regained a pulse.',
  COMPLETE = "Great job!  You successfully defibrillated the patient. Try another case.",
  AED_MODE = 'You have entered AED mode. The "ANALYZE" button should light up, and the machine will begin to issue audible prompts. Continuing CPR may interfere with the machine\'s ability to interpret the rhythm.<br><br> Click "Stop CPR" and allow the machine to analyze the rhythm.',
  CONTINUE_CPR = "Continue CPR before reassessing for a pulse.",
}

export enum TutorialCase2Prompts {
  INIT_ASSESS = 'First, assess the patient\'s condition by clicking the "Assess Patient" button.',
  TURN_ON = 'The patient is breathing and has a  pulse.  However, the heart rate is not high enough to sustain an adequate blood pressure. When medications fail to stabilize symptomatic bradycardia, temporary transcutaneous pacing is indicated. This case will teach you how to perform transcutaneous pacing.<br><br> Turn on the defibrillator  by pushing the "ON" button, and prepare to pace the patient\'s rhythm. ',
  REMOVE_TEST_PLUG = 'Next you will need to connect the patient to the defibrillator to display and assess the cardiac rhythm. The "ON" button\'s green light should be visible if the machine is on. <br><br>Use your mouse to drag away the black test plug and expose the grey adapter on the therapy cable.',
  CONNECT_ELECTRODES = "For optimal pacing, the Lifepak's own ECG leads must be connected to the patient, because the Quik-Combo therapy pads (aka \"electrodes\") cannot monitor the patient's rhythm and deliver pacing current at the same time.<br><br> Verify the ECG leads are attached to the patient. Then press the PACER button on the defibrillator console.",
  PACER = 'Once the ECG leads are attached, you can have the machine read the rhythm from Lead I, II, or III by pressing the "LEAD" button to select the desired lead. If the ECG leads are not attached, selecting Lead I, II, or III will not show the heart rhythm but instead will display a dashed line. <br><br> Now, use your mouse to press the PACER button',
  PACER2 = ' Note that the PR interval is prolonged, which can be caused by increased vagal tone in otherwise healthy children. In this case, it reflects drug toxicity. The rhythm is sinus bradycardia with 1st degree AV block. Using your mouse or touchpad, push the "PACER" button. The button\'s green light should go on. ',
  PACE_CONNECT_ECG_LEADS = "The pacer button's green light should be on. Notice that the patient's rhythm disapperars and dashed lines appear. This is because you indicated to the machine that you wish to pace, and the Quik-Combo pads cannot read the patient's rhythm and deliver pacing at the same time.<br><br> To see the rhythm displayed again, connect the Lifepak's ECG leads to the patient.",
  PRESS_CURRENT = 'A heart rate of 100/min is appropriate for most age groups. The monitor should display vertical pacing spikes occuring at a rate of 100 beats/minute. <br><br>Next, press "CURRENT". Using the up arrows, increase the current until the monitor shows that a QRS complex immediately follows EVERY vertical pacer spike.This is called "mechanical capture".  Using the down arrows, adjust the current so that you are using the minimum current that will produce a QRS complex immediately following EVERY vertical pacer spike.',
  PACING_ASSESS = 'Notice that a wide QRS complex now follows each vertical pacing spike. This is called "mechanical capture". <br><br>Now use your mouse or touchpad to click on the "Assess" button to verify the patient has a pulse at the desired rate (100 beats/min). If you press and hold the "PAUSE" button, you can check the patient\'s underlying rhythm. ',
  PRESSED_PAUSE = 'Pressing the "PAUSE" button allows you to check the patient\'s underlying rhythm. When the "PAUSE" button is held, the Lifepak paces at 25% of the set rate (in this case, 0.25 x 100 = 25 BPM). Because we are pacing in "demand" mode, and the patient\'s underlying rate is > 25, there is no demand for pacing and you will not see any vertical pacing spikes. ',
  ENTERED_AED_MODE = 'You have entered AED mode. You cannot pace in AED mode. Press "Energy Select" or "LEAD" to return to manual mode, and  then select the "PACER" button. ',
  // TODO: finish / next case prompt here
}

export enum TutorialCase3Prompts {
  INIT_ASSESS = 'First assess the patient\'s condition by clicking "Assess".',
  TURN_ON_DEFIB = 'The patient is breathing and has a pulse. Next you will need to connect the patient to the defibrillator to display and assess the cardiac rhythm.<br><br> Turn on the machine by pushing the "ON" button',
  CONNECT_PATIENT = 'The "ON" button\'s green light should be visible.<br><br> Use your mouse to drag away the black test plug and expose the grey adapter on the therapy cable. ',
  ENERGY_SELECT = 'Assess the rhythm. Note that the QRS complexes are fast, wide, and regular. QRS morphologies are identical and P waves are not identifiable, suggesting "monomorphic" ventricular tachycardia. The patient has a pulse, so this is is "Ventricular tachycardia with a pulse". Synchronized cardioversion is indicated.<br><br> First you will need to push "Energy Select" and use the arrows to select 0.5-2 Joules per kilogram body weight. ',
  PRESS_SYNC = 'Now, you will need to prepare the machine to deliver a shock ONLY on a QRS complex. If the shock is not synchronized to the QRS complex , this could trigger ventricular fibrillation. <br><br>Hit the "SYNC" button, located under the shock button on the console. ',
  CHARGE_DEFIB = 'The "SYNC" button\'s green light should be visible, and green arrows should project over each QRS complex.<br><br> You are now ready to hit the CHARGE button. ',
  SHOCK = 'Double check the "SYNC" button is engaged, the green arrows display over each QRS complex, and the selected energy dose is correct for cardioversion (0.5-2 Joules/kg). CLEAR staff from the patient before you shock!',
  COMPLETE = "Good job!  You successfully cardioverted the patient. You are ready to try another case. ",
  AED_MODE = 'You have entered AED mode. The Lifepak\'s default energy dose in AED mode is 200J, which is excessive for an infant. Press "Energy Select" or "LEAD" to return to manual mode, and enter the appropriate energy dose for cardioversion (0.5-2 Joules/kg). ',
  REASSESS_PATIENT = 'Now reassess your patient. Click "ASSESS PATIENT".',
}
