import { Group, Text } from "react-konva";
import { KonvaImage } from "./UIComponents";
import { PatientAvatar } from "./model/patient";

type Position = {
  x: number;
  y: number;
};

export enum ConnectorType {
  LAECG = "LAECG",
  LLECG = "LLECG",
  RAECG = "RAECG",
  LeftPad = "LeftPad",
  RightPad = "RightPad",
}

type PatientSnapPositions = {
  [key in ConnectorType]: Position;
};

const PATIENT_AVATAR_TO_SNAP_POSITION: Record<
  PatientAvatar,
  PatientSnapPositions
> = {
  [PatientAvatar.OlderMan]: {
    [ConnectorType.LeftPad]: { x: 853, y: 420 },
    [ConnectorType.RightPad]: { x: 966, y: 440 },
    [ConnectorType.LAECG]: {
      x: 895,
      y: 360,
    },
    [ConnectorType.LLECG]: {
      x: 925,
      y: 475,
    },
    [ConnectorType.RAECG]: {
      x: 854,
      y: 394,
    },
  },
  [PatientAvatar.Teenager]: {
    [ConnectorType.LeftPad]: { x: 853, y: 420 },
    [ConnectorType.RightPad]: { x: 966, y: 440 },
    [ConnectorType.LAECG]: {
      x: 895,
      y: 360,
    },
    [ConnectorType.LLECG]: {
      x: 925,
      y: 475,
    },
    [ConnectorType.RAECG]: {
      x: 854,
      y: 390,
    },
  },
  [PatientAvatar.Infant]: {
    [ConnectorType.LeftPad]: { x: 880, y: 447 },
    [ConnectorType.RightPad]: { x: 963, y: 460 },
    [ConnectorType.LAECG]: {
      x: 887,
      y: 390,
    },
    [ConnectorType.LLECG]: {
      x: 922,
      y: 498,
    },
    [ConnectorType.RAECG]: {
      x: 890,
      y: 410,
    },
  },
  [PatientAvatar.Todler]: {
    [ConnectorType.LeftPad]: { x: 853, y: 444 },
    [ConnectorType.RightPad]: { x: 948, y: 458 },
    [ConnectorType.LAECG]: {
      x: 887,
      y: 390,
    },
    [ConnectorType.LLECG]: {
      x: 914,
      y: 498,
    },
    [ConnectorType.RAECG]: {
      x: 858,
      y: 405,
    },
  },
};

export function snapPositions(PatientAvatar: PatientAvatar) {
  return PATIENT_AVATAR_TO_SNAP_POSITION[PatientAvatar];
}

export function PatientDisplay({ patientAvatar, patientWeight }) {
  return (
    <Group x={810} y={220}>
      {patientWeight && (
        <Text
          text={`weight: ${patientWeight}kg`}
          fontStyle="bold"
          fontSize={15}
          x={50}
          y={-20}
        />
      )}
      <KonvaImage
        src={patientAvatarToImageSrc[patientAvatar]}
        // draggable={true}
        scaleX={patientAvatarToImageScale[patientAvatar]}
        scaleY={patientAvatarToImageScale[patientAvatar]}
      />
    </Group>
  );
}

const patientAvatarToImageSrc: Record<PatientAvatar, string> = {
  [PatientAvatar.OlderMan]: "assets/olderman.png",
  [PatientAvatar.Teenager]: "assets/adult-teen.png",
  [PatientAvatar.Infant]: "assets/infant.png",
  [PatientAvatar.Todler]: "assets/child.png",
};

const patientAvatarToImageScale: Record<PatientAvatar, number> = {
  [PatientAvatar.OlderMan]: 0.45,
  [PatientAvatar.Teenager]: 0.45,
  [PatientAvatar.Infant]: 0.47,
  [PatientAvatar.Todler]: 0.48,
};
