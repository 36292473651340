import { TutorialCase1Prompts, TutorialCase2Prompts } from "@/TutorialPrompts";
import { Defibrillator } from "./defibrillator";
import { HeartRhythm, Patient } from "./patient";
import { SceneConfig } from "@/scenes";

export class GameState {
  patient: Patient;
  defibrillator: Defibrillator;
  configuration: any;
  dieTimeout: any;
  patientNeedsCPR: boolean;
  whatsNextText = "";

  hasConnectedElectrodes = false;

  tutorialStateCalculator: TutorialStateCalculator;

  returnState = {};

  constructor(
    patient: Patient,
    defibrillator: Defibrillator,
    configuration: SceneConfig
  ) {
    this.patient = patient;
    this.defibrillator = defibrillator;
    this.configuration = configuration;
    this.patientNeedsCPR = false;
    this.dieTimeout = setTimeout(() => {
      this.patient.die();
    }, configuration.idleDeathTimeout);

    if (typeof window !== "undefined") {
      window.addEventListener(
        "defibStateChange",
        this.hasWonTheCase.bind(this)
      );
      window.addEventListener(
        "patientStateChange",
        this.hasWonTheCase.bind(this)
      );
    }

    this.tutorialStateCalculator = new TutorialStateCalculator(
      defibrillator,
      patient,
      this
    );
    this.tutorialStateCalculator.calculateReturnState();
    if (typeof window !== "undefined") {
      window.addEventListener(
        "defibStateChange",
        this.tutorialStateCalculator.calculateReturnState.bind(
          this.tutorialStateCalculator
        )
      );
      window.addEventListener(
        "patientStateChange",
        this.tutorialStateCalculator.calculateReturnState.bind(
          this.tutorialStateCalculator
        )
      );
    }
  }

  gameStateReturnState() {
    let retState = {
      whatsNextText: this.whatsNextText,
    };
    if (JSON.stringify(retState) !== JSON.stringify(this.returnState)) {
      this.returnState = retState;

      dispatchEvent(new Event("gameStateStateChange"));
    }
    return retState;
  }

  destroy(): void {
    clearTimeout(this.dieTimeout);
    this.dieTimeout = null;
  }

  hasWonTheCase() {
    console.debug("hasWonTheCase");
    if (
      this.patient.heartRhythm == HeartRhythm.NormalRhythm &&
      this.patientNeedsCPR == false
    ) {
      if (typeof window !== "undefined") {
        alert("won!");
      }
      return true;
    } else {
      return false;
    }
  }

  hasConnectedElectrodesToPatient() {
    if (
      !this.hasConnectedElectrodes &&
      this.defibrillator.isConnectedToPatient()
    ) {
      this.hasConnectedElectrodes = true;
      this.tutorialStateCalculator.hasConnectedElectrodes = true;
      this.tutorialStateCalculator.calculateReturnState();
    }
  }

  shockDecision(joules: number) {
    if (
      this.patient.heartRhythm == HeartRhythm.VFib &&
      joules >= this.configuration.shockConditions.lowerBoundShock &&
      joules <= this.configuration.shockConditions.upperBoundShock
    ) {
      clearTimeout(this.dieTimeout);
      this.dieTimeout = null;
      this.patientNeedsCPR = true;
      this.patient.changeHeartState({
        heartRhythm: HeartRhythm.NormalRhythm,
        heartRate: 60,
      });
    }

    if (joules > this.configuration.shockConditions.upperBoundShock) {
      this.patient.die();
      clearTimeout(this.dieTimeout);
      this.dieTimeout = null;
    }
  }

  clickedAssessPatient() {
    this.tutorialStateCalculator.hasAssessedPatient = true;
    this.tutorialStateCalculator.calculateReturnState();
  }
  setWhatsNextText(text: string) {
    this.whatsNextText = text;
    this.gameStateReturnState();
  }
}

class TutorialStateCalculator {
  defibrillator: Defibrillator;
  patient: Patient;
  gameState: GameState;

  hasAssessedPatient = false;
  hasStartedCPR = false;
  hasConnectedElectrodes = false;
  hasRemovedTestPlug = false;
  hasTurnedOnDefib = false;

  constructor(defib: Defibrillator, patient: Patient, gameState: GameState) {
    this.defibrillator = defib;
    this.patient = patient;
    this.gameState = gameState;
  }

  calculateReturnState() {
    if (this.patient.heartRhythm == HeartRhythm.SlowRhythm) {
      if (!this.hasAssessedPatient) {
        this.gameState.setWhatsNextText(TutorialCase2Prompts.INIT_ASSESS);
        return;
      } else if (!this.hasTurnedOnDefib) {
        this.gameState.setWhatsNextText(TutorialCase2Prompts.TURN_ON);
        return;
      } else if (this.hasTurnedOnDefib && !this.hasRemovedTestPlug) {
        this.gameState.setWhatsNextText(TutorialCase2Prompts.REMOVE_TEST_PLUG);
        return;
      } else if (
        (this.hasRemovedTestPlug &&
          !this.defibrillator.isConnectedToPatient()) ||
        !this.defibrillator.isEKGConnected()
      ) {
        this.gameState.setWhatsNextText(
          TutorialCase2Prompts.CONNECT_ELECTRODES
        );
        return;
      } else if (
        this.defibrillator.isConnectedToPatient() &&
        this.defibrillator.isEKGConnected() &&
        !this.defibrillator.isPacerOn
      ) {
        this.gameState.setWhatsNextText(TutorialCase2Prompts.PACER);
        return;
      } else if (
        this.defibrillator.isPacerOn &&
        this.defibrillator.pacerCurrent < 100
      ) {
        this.gameState.setWhatsNextText(TutorialCase2Prompts.PRESS_CURRENT);
        return;
      } else if (this.defibrillator && this.defibrillator.pacerCurrent >= 100) {
        this.gameState.setWhatsNextText(TutorialCase2Prompts.PACING_ASSESS);
        return;
      }
    }
    if (this.patient.heartRhythm == HeartRhythm.VFib) {
      if (!this.hasAssessedPatient) {
        this.gameState.setWhatsNextText(TutorialCase1Prompts.INIT_ASSESS);
        return;
      } else if (this.hasAssessedPatient && !this.hasStartedCPR) {
        this.gameState.setWhatsNextText(TutorialCase1Prompts.START_CPR);
        return;
      } else if (this.hasStartedCPR && !this.hasTurnedOnDefib) {
        this.gameState.setWhatsNextText(TutorialCase1Prompts.TURN_ON);
        return;
      } else if (this.hasTurnedOnDefib && !this.hasRemovedTestPlug) {
        this.gameState.setWhatsNextText(TutorialCase1Prompts.REMOVE_TEST_PLUG);
        return;
      } else if (
        this.hasRemovedTestPlug &&
        !this.defibrillator.isConnectedToPatient()
      ) {
        this.gameState.setWhatsNextText(
          TutorialCase1Prompts.CONNECT_ELECTRODES
        );
        return;
      } else if (
        this.defibrillator.isConnectedToPatient() &&
        (!this.defibrillator.isOn || this.defibrillator.chargeJoules < 200)
      ) {
        this.gameState.setWhatsNextText(
          TutorialCase1Prompts.LOOK_AT_VFIB_RHYTHM
        );
      } else if (
        this.defibrillator.isOn &&
        this.defibrillator.chargeJoules >= 200 &&
        !this.defibrillator.isShockReady
      ) {
        this.gameState.setWhatsNextText(TutorialCase1Prompts.CHARGE);
      } else if (this.defibrillator.isOn && this.defibrillator.isShockReady) {
        this.gameState.setWhatsNextText(
          TutorialCase1Prompts.STOP_CPR_AND_SHOCK
        );
      }
    } else if (this.patient.heartRhythm == HeartRhythm.NormalRhythm) {
      if (!this.patient.isCPRBeingPerformed) {
        this.gameState.setWhatsNextText(TutorialCase1Prompts.RESTART_CPR);
      } else if (
        this.patient.heartRhythm == HeartRhythm.NormalRhythm &&
        this.patient.isCPRBeingPerformed
      ) {
        this.gameState.setWhatsNextText(TutorialCase1Prompts.COMPLETE);
      }
    }
  }
}
function dispatchEvent(event: Event) {
  if (typeof window !== "undefined") {
    window.dispatchEvent(event);
  }
}
