import { Group, Rect, Text } from "react-konva";
import { ChargingBar } from "./ChargingBar";
import { useDefib } from "@/DefibContextManager";

export function ChargingScreen() {
  const {
    defibState: { chargeJoules, isCharging },
  } = useDefib();
  return (
    <Group x={0} y={140}>
      <Rect width={360} height={130} fill="lightblue"></Rect>
      <Rect width={360} height={20} x={0} fill="blue"></Rect>
      <Rect width={360} height={20} x={0} y={110} fill="blue"></Rect>
      <Text
        width={360}
        height={20}
        align="center"
        fontStyle="bold"
        fontSize={16}
        y={25}
        fill="black"
        text="Charging"
      ></Text>
      <ChargingBar isCharging={isCharging} />
      <Text
        width={360}
        height={20}
        y={43}
        fontSize={16}
        fill="black"
        text={`${chargeJoules}J`}
        align="center"
        fontStyle="bold"
      ></Text>

      <Text
        width={360}
        height={20}
        y={90}
        fontSize={16}
        fill="black"
        text={"Push Speed Dial to Disarm"}
        align="center"
        fontStyle="bold"
      ></Text>
    </Group>
  );
}
