import { Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";

export function ECGCablePort({ isOverview }) {
  const infoBoxProps = useInfoBox({
    title: "ECG Port",
    description:
      "To connect the Lifepak's own ECG leads, drag the ECG cable to the green ECG Cable Port and drag the individual leads to the patient.",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      x={95}
      y={565}
      onClick={() => {
        if (isOverview) return;
      }}
    >
      <Rect
        width={60}
        height={60}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
    </ClickableGroup>
  );
}
