import { useContext } from "react";
import { Rect, Text, Group } from "react-konva";
import { InfoBoxContext } from "./InfoBoxContextManager";

export function InfoBox() {
  const infoBox = useContext(InfoBoxContext);

  return (
    <Group x={1100} y={200}>
      <Rect width={200} height={100} stroke="black"></Rect>
      <Text text={infoBox.title} fontStyle="bold" x={2} />
      <Text width={200} text={infoBox.description} y={15} x={2} />
    </Group>
  );
}
