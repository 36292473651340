import { useEffect, useState } from "react";
import { KonvaImage } from "../../../UIComponents";

export function Heart() {
  const [isVisible, setIsVisible] = useState(true);
  const [intervalDuration, setIntervalDuration] = useState(900);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prev) => !prev);
      // Toggle between 900ms and 100ms intervals
      setIntervalDuration((prevDuration) => (prevDuration === 900 ? 100 : 900));
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [intervalDuration]);

  return (
    <KonvaImage y={15} src="assets/Heart.png" opacity={isVisible ? 1 : 0} />
  );
}
