import { KonvaImage, WiredElement } from "../../../UIComponents";
import { useInfoBox } from "../../../App";

export function TherapyCable({ set }) {
  const infoBoxProps = useInfoBox({
    title: "Therapy Cable",
    description:
      "The therapy cable must be connected to its attachment port in order for the pads to work. You can use your mouse to attach the therapy cable to the Quik-Combo pads once you drag off the black test plug.",
  });
  return (
    <WiredElement
      {...infoBoxProps}
      draggable={true}
      x={530}
      y={550}
      scaleX={1.2}
      scaleY={1.2}
      set={set}
    >
      <KonvaImage src="assets/TherapyCable.png" />
    </WiredElement>
  );
}
