import { Group, Image } from "react-konva";
import { useState, useEffect } from "react";

export function ClickableGroup({
  children,
  onMouseEnter = (e) => {
    e;
  },
  onMouseLeave = (e) => {
    e;
  },
  onClick = (e) => {
    e;
  },
  ...props
}) {
  return (
    <Group
      onMouseLeave={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = "default";

        if (onMouseLeave) onMouseLeave(e);
      }}
      onMouseEnter={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = "pointer";

        onMouseEnter(e);
      }}
      onTap={(e) => {
        onClick(e);
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Group>
  );
}

export function WiredElement({
  set,
  onDragMove = (e) => {
    e;
  },
  children,
  ...props
}) {
  return (
    <ClickableGroup
      onDragMove={(e) => {
        onDragMove(e);
        const pos = e.target.getAbsolutePosition();
        set({ x: pos.x, y: pos.y });
      }}
      {...props}
    >
      {children}
    </ClickableGroup>
  );
}

export function KonvaImage({ src, ...props }) {
  // const { draggable, src } = props;
  const [image, setImage] = useState(null);

  useEffect(() => {
    const img = new window.Image();
    img.src = src;
    img.onload = () => {
      setImage(img);
    };
  }, [src]);
  const childProps = { ...props };

  return <Image image={image} {...childProps} />;
}
