import { HeartRhythm, PatientAvatar, Patient } from "./model/patient";
import { InfoBoxContextProvider } from "./InfoBoxContextManager";
import { InfoBox } from "./InfoBox";
import { PatientDisplay } from "./Patient";
import { DefibrillatorComponent } from "./defibrillators/lifepak/ui/DefibrillatorComponent";
import { Group, Layer, Rect, Text } from "react-konva";
import { ClickableGroup } from "./UIComponents";
import { useDefib } from "./DefibContextManager";
import { useEffect, useState } from "react";
import { GameState } from "./model/gameState";

export function Simulation({
  isOverview,
  patientHeartRhythm,
  patientAvatar,
  caseDescription,
  patientStatus,
  patientHeartRate,
  patientIsCPRBeingPerformed,
  patientWeight,
  patient,
  isTutorial,
  gameStateState,
  gameState,
}: {
  isOverview: boolean;
  patientHeartRhythm?: HeartRhythm;
  patientAvatar: PatientAvatar;
  patientStatus: string;
  caseDescription: string;
  patientHeartRate: number;
  patientWeight: number;
  patientIsCPRBeingPerformed: boolean;
  isTutorial: boolean;
  patient: Patient;
  gameStateState: any;
  gameState: GameState;
}) {
  const {
    defibState: { isConnectedToPatient },
  } = useDefib();
  return (
    <InfoBoxContextProvider>
      <Layer>
        <PatientDisplay
          patientAvatar={patientAvatar}
          patientWeight={patientWeight}
        />
      </Layer>
      <DefibrillatorComponent
        isOverview={isOverview}
        patientHeartRhythm={isConnectedToPatient ? patientHeartRhythm : null}
        patientAvatar={patientAvatar}
        patientHeartRate={isConnectedToPatient ? patientHeartRate : null}
      />
      <Layer>
        {!isOverview && (
          <>
            <CaseDescription caseDescription={caseDescription} />
            <AssessPatient
              patientStatus={patientStatus}
              gameState={gameState}
            />
            <StartCPR
              patient={patient}
              patientIsCPRBeingPerformed={patientIsCPRBeingPerformed}
            />
          </>
        )}

        {isOverview && <InfoBox />}
        {isTutorial && <TutorialInfoBox gameStateState={gameStateState} />}
      </Layer>
    </InfoBoxContextProvider>
  );
}

function CaseDescription({ caseDescription }: { caseDescription: string }) {
  return (
    <ClickableGroup
      x={1100}
      y={170}
      onClick={() => {
        alert(caseDescription);
      }}
    >
      <Rect width={80} height={50} fill="blue"></Rect>
      <Text
        width={80}
        text="Case Description"
        align="center"
        y={15}
        x={0}
        fontStyle="bold"
        fill="white"
      />
    </ClickableGroup>
  );
}

function AssessPatient({
  patientStatus,
  gameState,
}: {
  patientStatus: string;
  gameState: GameState;
}) {
  return (
    <ClickableGroup
      x={1100}
      y={240}
      onClick={() => {
        gameState.clickedAssessPatient();
        alert(patientStatus);
      }}
    >
      <Rect width={80} height={50} fill="blue"></Rect>
      <Text
        width={80}
        text="Assess Patient"
        align="center"
        y={15}
        x={0}
        fontStyle="bold"
        fill="white"
      />
    </ClickableGroup>
  );
}

function StartCPR({
  patientIsCPRBeingPerformed,
  patient,
}: {
  patientIsCPRBeingPerformed: boolean;
  patient: Patient;
}) {
  return (
    <>
      {patientIsCPRBeingPerformed && <CPRisOnFlashingText />}
      <ClickableGroup
        x={1100}
        y={320}
        onClick={() => {
          patient.setIsCPRBeingPerformed(!patientIsCPRBeingPerformed);
        }}
      >
        <Rect width={80} height={35} fill="green" stroke="red"></Rect>
        <Text
          width={80}
          text="Start CPR"
          align="center"
          y={10}
          x={0}
          fontStyle="bold"
          fill="white"
        />
      </ClickableGroup>
    </>
  );
}

function CPRisOnFlashingText() {
  const [textOn, setTextOn] = useState(true);

  // Handle the flashing of the light
  useEffect(() => {
    let interval = setInterval(() => {
      setTextOn((prev) => !prev);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [textOn]);

  return (
    <>
      {textOn && (
        <Text
          x={1105}
          y={300}
          fill="red"
          fontStyle="bold"
          fontSize={15}
          text={"CPR IS ON"}
        ></Text>
      )}
    </>
  );
}

function TutorialInfoBox({ gameStateState }) {
  return (
    <Group x={1100} y={400}>
      <Text
        text={gameStateState.whatsNextText}
        width={200}
        y={20}
        align="center"
      />
      <Rect stroke="black" width={200} height={200}></Rect>
    </Group>
  );
}
