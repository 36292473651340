import { HeartRhythm } from "../../../model/patient";

const heartStateToPulseDisplay: Record<HeartRhythm, string> = {
  [HeartRhythm.NormalRhythm]: "assets/NormalRate.png",
  [HeartRhythm.VFib]: "assets/vfib.png",
  [HeartRhythm.NoPulse]: "assets/DeadLine.png",
  [HeartRhythm.VTac]: "assets/vtac.png",
  [HeartRhythm.SlowRhythm]: "assets/SlowRhythm.png",
};

import { KonvaImage } from "@/UIComponents";
import Konva from "konva";
import { useRef, useEffect } from "react";
import { Group, Rect } from "react-konva";

export function Pulse({ patientHeartRhythm }) {
  const rectRef = useRef(null);

  useEffect(() => {
    const speed = 100; // pixels per second
    const maxDistance = 340; // maximum distance to travel
    let lastTime = 0;

    const anim = new Konva.Animation((frame) => {
      if (!lastTime) lastTime = frame.time;
      const deltaTime = frame.time - lastTime;
      lastTime = frame.time;

      let newX = rectRef.current.x() + (speed * deltaTime) / 1000;
      if (newX > maxDistance) {
        // Reset position to start
        newX = 0;
      }
      rectRef.current.x(newX);
    }, rectRef.current.getLayer());

    anim.start();

    return () => {
      anim.stop();
      return;
    };
  }, [rectRef]);

  let src;
  if (patientHeartRhythm) {
    src = heartStateToPulseDisplay[patientHeartRhythm];
  } else {
    src = "assets/Flatline.png";
  }

  return (
    <Group y={80}>
      <KonvaImage src={src} />
      <Rect ref={rectRef} width={10} height={50} x={0} fill="black" />
    </Group>
  );
}
