export function InfoScreen({ setShowInfoDialog }) {
  return (
    <div>
      <h1>
        Welcome to the PhysioControl Lifepak&#169; 20e Defibrillator Simulator!
      </h1>
      <h3>
        This is a real-time simulator that allows you to learn how the
        Lifepak&#169; 20e defibrillator works by interacting with its functions
        and accessories.
        <br />
        <br /> This simulator has 3 components:
      </h3>
      <ol>
        <li>
          An interactive tour of the defibrillator's features and accessories
        </li>
        <li>
          A case-based tutorial that guides you through the management of case
          scenarios requiring defibrillation, synchronized cardioversion, or
          transcutaneous pacing
        </li>
        <li>
          A series of cases designed to test your ability to use the
          defibrillator to correct rhythm disturbances within a suggested
          timeframe
        </li>
      </ol>
      <p>
        Begin using this simulator by using your mouse or touchpad to select
        "Introduction to the Defibrillator's Features and Accessories" and
        review the basic features and functions of the machine. After you
        complete the Introductory module you can proceed to the case-based
        Tutorial and then to the Test cases. You must complete the Test cases to
        receive credit for completing this course.
      </p>
      Contributors:
      <p>
        Joshua Ferge, University of Colorado, Boulder CO
        <br />
        Kathleen M. Ventre, MD University of Colorado, Denver CO
        <br />
        Anne Brenneman MSN, CPNP Primary Children's Hospital, Salt Lake City UT
        <br />
        <i>All patient illustrations &copy 2014 Intermountain Healthcare</i>
      </p>
      <i>
        This interactive program is intended as a training tool for licensed
        health care professionals. It may not be counted upon as dispensing
        medical advice or treatment. The authors cannot warrant that the program
        is free from errors or that it contains the most current treatment
        recommendations. Users are responsible for verifying the accuracy of any
        medical information before prescribing a specific therapy
      </i>
      <br />
      <button
        style={{ backgroundColor: "blue" }}
        onClick={() => {
          setShowInfoDialog(false);
          localStorage.setItem("hasSeenInfoDialog", "true");
        }}
      >
        OK!
      </button>
    </div>
  );
}
