import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { HomePage } from "./pages/HomePage.tsx";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://dd5dfc90ce6cce0ce34fdd0878cc2aae@o1093633.ingest.sentry.io/4506353548394496",
    integrations: [new Sentry.BrowserTracing({})],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    environment: import.meta.env.MODE,
  });
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "lifepak",
    element: <App />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <RouterProvider router={router} />
);
