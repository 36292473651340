import { Circle, Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";
import { useDefib } from "@/DefibContextManager";

export function Sync({ isOverview }) {
  const {
    defib,
    defibState: { isSyncOn },
  } = useDefib();
  const infoBoxProps = useInfoBox({
    title: "Sync",
    description:
      "Pressing the SYNC button engages the machine's ability to time the delivery of a shock to the patient's QRS complex. You must press and HOLD the Shock Button.<br> <a class='youtube' href='https://www.youtube.com/watch?v=4yQvfTjHlHw'>Synchronized Cardioversion <img class='playicon' style='vertical-align:bottom'  width='30' src='assets/playicon.png'></a>",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      x={640}
      y={298}
      onClick={() => {
        if (isOverview) return;
        defib.sync();
      }}
    >
      <Rect
        width={85}
        height={35}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
      {isSyncOn && <Circle x={14} y={18} radius={5} fill="lightgreen"></Circle>}
    </ClickableGroup>
  );
}
