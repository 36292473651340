import { Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";

export function Size() {
  const infoBoxProps = useInfoBox({
    title: "Size",
    description:
      "This feature is not essential for defibrillator operation and will not be covered in this module",
  });

  return (
    <ClickableGroup {...infoBoxProps} x={585} y={295}>
      <Rect width={38} height={35} stroke={OVERVIEW_OUTLINE_STROKE_COLOR} />
    </ClickableGroup>
  );
}
