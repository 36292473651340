import { Rect, Circle } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";
import { useDefib } from "@/DefibContextManager";

export function OnButton({ isOverview }) {
  const {
    defib,
    defibState: { isOn, isTurningOn },
  } = useDefib();
  const infoBoxProps = useInfoBox({
    title: "On",
    description:
      "ON button: Turns machine power ON or OFF. Turning the machine ON is the first step in performing any function with the Lifepak defibrillator.",
  });

  const onClick = () => {
    if (isOverview) return;
    defib.onButton();
  };

  return (
    <ClickableGroup {...infoBoxProps} x={640} y={130} onClick={onClick}>
      <>
        {!isTurningOn && !isOn && (
          <Circle radius={5} fill="black" x={15} y={20}></Circle>
        )}

        <Rect
          width={90}
          height={35}
          stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
        />
      </>
    </ClickableGroup>
  );
}
