import { Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";
import { useDefib } from "@/DefibContextManager";

export function EnergySelectDown({ isOverview }) {
  const { defib } = useDefib();
  const infoBoxProps = useInfoBox({
    title: "Energy Select Down",
    description:
      "This control allows the user to select the desired energy dose by pushing the up or down arrows.",
  });

  const handlePress = () => {
    if (isOverview) return;
    defib.energySelectDown();
  };

  return (
    <ClickableGroup {...infoBoxProps} x={640} y={166} onClick={handlePress}>
      <Rect
        width={45}
        height={35}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
    </ClickableGroup>
  );
}
