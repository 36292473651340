import { PadType, useInfoBox, SNAP_DISTANCE } from "../../../App";
import { distanceFormula } from "../../../utils";

import { WiredElement, KonvaImage } from "../../../UIComponents";
import { useDefib } from "@/DefibContextManager";

export function Pad({ padType, set, padPosition, snapPosition }) {
  const { defib } = useDefib();
  let imageSrc;
  let setMethod;
  if (padType === PadType.LEFT) {
    setMethod = defib.setLeftPadConnected.bind(defib);
    imageSrc = "assets/LeftPad.png";
  } else {
    setMethod = defib.setRightPadConnected.bind(defib);
    imageSrc = "assets/RightPad.png";
  }

  const infoBoxProps = useInfoBox({
    title: "Quik-Combo Pad",
    description:
      "Multifunction electrodes that allow the defibrillator to deliver an electrical stimulus to the patient. Attaching the Quik-Combo pads is a multistep process. <a style='color:blue; cursor: pointer; text-decoration:underline' onclick='showMeHow2()'>Show Me How</a>",
  });

  return (
    <WiredElement
      {...infoBoxProps}
      scaleX={0.23}
      scaleY={0.23}
      x={padPosition.x}
      y={padPosition.y}
      set={set}
      draggable={true}
      onDragMove={(e) => {
        if (
          distanceFormula(e.target.getAbsolutePosition(), snapPosition) <
          SNAP_DISTANCE
        ) {
          setMethod(true);
          e.target.setAbsolutePosition({
            x: snapPosition.x - 20,
            y: snapPosition.y - 24,
          });
          return;
        }
        setMethod(false);
      }}
    >
      <KonvaImage src={imageSrc} />
    </WiredElement>
  );
}
