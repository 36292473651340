import React, { useState } from "react";

export const InfoBoxContext = React.createContext({
  title: "",
  description: "",
  setValues: ({ title, description }) => {
    void [title, description];
  },
});

export const InfoBoxContextProvider = (props) => {
  const setValues = ({ title, description }) => {
    setState({ ...state, title: title, description: description });
  };

  const initState = {
    title: "",
    description: "",
    setValues: setValues,
  };

  const [state, setState] = useState(initState);

  return (
    <InfoBoxContext.Provider value={state}>
      {props.children}
    </InfoBoxContext.Provider>
  );
};
