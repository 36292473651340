import {
  HeartRhythm,
  PatientAvatar,
  PatientParameters,
  Sex,
} from "./model/patient";

export enum Scene {
  // TODO: figure out naming and Object.keys of this
  Overview = "Overview",
  TutorialCase1 = "TutorialCase1",
  TutorialCase2 = "TutorialCase2",
  TutorialCase3 = "TutorialCase3",
  TestCase1 = "TestCase1",
  TestCase2 = "TestCase2",
  TestCase3 = "TestCase3",
}
type SceneParameters = {
  isTutorial: boolean;
  title: string;
  description: string;
  patientParams: PatientParameters;
  configuration: SceneConfig;
};

export type SceneConfig = {
  idleDeathTimeout: number;
  shockConditions: {
    lowerBoundShock?: number;
    upperBoundShock?: number;
    shockNeeded?: number;
  } | null;
  needsCPR?: boolean;
  isTutorial: boolean;
};

type SceneInfoType = Record<Scene, SceneParameters>;

export const sceneInfo: SceneInfoType = {
  [Scene.Overview]: {
    title: "",
    description: "",
    patientParams: {
      age: 15,
      alive: true,
      heartRate: 0,
      heartRhythm: HeartRhythm.NormalRhythm,
      isCPRBeingPerformed: false,
      weight: 50,
      avatar: PatientAvatar.Teenager,
      sex: Sex.Male,
      status: "He is not breathing. You cannot feel a central pulse.",
    },
    configuration: {
      idleDeathTimeout: 60000,
      shockConditions: {
        shockNeeded: 150,
      },
      isTutorial: true,
    },
    isTutorial: true,
  },
  [Scene.TutorialCase1]: {
    title: "Case I: A patient's family member collapses!",
    description:
      "</p>As you return to duty after your lunch break, you are urgently called to a patient room, where a patient's grandfather has collapsed to the floor. This is a patient's family member. You do not know the exact weight. He is of normal adult size.</p>",
    patientParams: {
      age: 15,
      alive: true,
      heartRate: 175,
      heartRhythm: HeartRhythm.VFib,
      isCPRBeingPerformed: false,
      weight: null,
      avatar: PatientAvatar.OlderMan,
      sex: Sex.Male,
      status: "He is not breathing. You cannot feel a central pulse.",
    },
    configuration: {
      idleDeathTimeout: 60000,
      shockConditions: {
        lowerBoundShock: 150,
        upperBoundShock: 360,
      },
      needsCPR: true,
      isTutorial: true,
    },
    isTutorial: true,
  },
  [Scene.TutorialCase2]: {
    // TODO
    title: "Case II: A toddler ingests Grandma's pills!",
    description:
      "You are called to the Emergency Department to assist staff with resuscitating a 4 year old patient who has a persistently slow cardiac rhythm after ingesting several of his grandmother's blood pressure pills. The patient remains symptomatically bradycardic despite attempts at medical management. Cardiology has recommended initiating transcutaneous pacing. Quickly assess the patient.",
    patientParams: {
      age: 4,
      alive: true,
      heartRate: 0,
      heartRhythm: HeartRhythm.SlowRhythm,
      isCPRBeingPerformed: false,
      weight: 14,
      avatar: PatientAvatar.Todler,
      sex: Sex.Male,
      status:
        "The patient is somnolent but responsive to stimuli. He is breathing spontaneously. He has a central pulse. BP is 56/30",
    },
    isTutorial: true,
    configuration: {
      idleDeathTimeout: 60000,
      isTutorial: true,
      shockConditions: null,
    },
  },
  [Scene.TutorialCase3]: {
    // TODO
    title: "Case III: An infant is not responsive!",
    description:
      "<p>A 10kg 11 month old infant with suspected myocarditis is admitted to your unit. As you head over to assess this patient, a nurse calls out that the infant has suddenly become less responsive.</p>",
    patientParams: {
      age: 0.9,
      alive: true,
      heartRate: 0,
      heartRhythm: HeartRhythm.VTac,
      isCPRBeingPerformed: false,
      weight: 10,
      avatar: PatientAvatar.Infant,
      sex: Sex.Female,
      status:
        "The infant is breathing. He is somewhat pale. You feel a faint central pulse.",
    },
    isTutorial: true,
    configuration: {
      isTutorial: true,
      idleDeathTimeout: 60000,
      shockConditions: {}, // TODO SHOCK conditions for this case,
    },
  },
  [Scene.TestCase1]: {
    // TODO
    title: "Test Case I: A baby becomes unresponsive!",
    description:
      "<p>You are on duty in the Emergency Department when a 6 kg 6 month old infant is rushed in, limp and minimally responsive. Mother states that the baby has had increased respiratory effort and has been feeding poorly. Mother thought he had a viral respiratory infection. As you approach, the infant stops breathing and becomes unresponsive. <br><br> Quickly assess the infant. </p>",
    patientParams: {
      age: 0.5,
      alive: true,
      heartRate: 0,
      heartRhythm: HeartRhythm.VFib,
      isCPRBeingPerformed: false,
      weight: 6,
      avatar: PatientAvatar.Infant,
      sex: Sex.Male,
      status: "The baby is cyanotic and unresponsive. There is no pulse.",
    },
    configuration: {
      idleDeathTimeout: 60000,
      shockConditions: {
        lowerBoundShock: 10,
        upperBoundShock: 60,
      },
      isTutorial: false,
    },
    isTutorial: false,
  },
  [Scene.TestCase2]: {
    // TODO
    title: "Test Case II: An inconsolable baby.",
    description:
      "<p>You are called to help stabilize an 8.5 kg, 6 month old female in the Emergency Department. The baby's mother says the baby was well until 2 days ago when he developed inconsolable fussiness and poor feeding. The baby sometimes seems dusky during these episodes. The baby was afebrile, dusky, and inconsolably crying on arrival. The Cardiology fellow assigned to the ED administered medication for what he thought was an abnormal heart rhythm. The infant did not respond to the medication and the blood pressure has fallen. Cardiology now feels that a defibrillator is needed and has asked you to bring it to the bedside.<br><br> Quickly assess the patient.</p>",
    patientParams: {
      age: 0.5,
      alive: true,
      heartRate: 0,
      heartRhythm: HeartRhythm.VTac,
      isCPRBeingPerformed: false,
      weight: 8.5,
      avatar: PatientAvatar.Infant,
      sex: Sex.Female,
      status:
        "The baby is awake and fussy, with grunting respirations. There are palpable pulses. BP is 55/21.",
    },
    isTutorial: false,
    configuration: {
      isTutorial: false,
      idleDeathTimeout: 60000,
      shockConditions: null,
    },
  },
  [Scene.TestCase3]: {
    // TODO
    title: "Case III: A lethargic teen comes to the ED!",
    description:
      "<p>A 65kg, 17 yo male is brought to the Emergency Department with history of lethargy and suspected multidrug ingestion. He had been at a 'rave' and his friend called EMS when he seemed 'out of it'. The patient was arousable at the scene, and intubation was deferred because the patient was maintaining his own airway. On arrival at the Emergency Department the patient was mildy tachycardic, with temp 38C and pupils 2mm. You are now called to assess the patient when a nurse notices a change in his cardiac rhythm that has not responded to his medications. <br><br>Quickly evaluate the patient. You must use the defibrillator/pacer to stabilize his condition.</p>",
    patientParams: {
      age: 0.5,
      alive: true,
      heartRate: 0,
      heartRhythm: HeartRhythm.SlowRhythm,
      isCPRBeingPerformed: false,
      weight: 65,
      avatar: PatientAvatar.Teenager,
      sex: Sex.Male,
      status:
        "The patient is sleepy but arousable. He is breathing spontaneously and has a palpable pulse. BP is 64/29.",
    },
    isTutorial: false,
    configuration: {
      isTutorial: false,
      idleDeathTimeout: 60000,
      shockConditions: null,
    },
  },
};
