import { KonvaImage, WiredElement } from "../../../UIComponents";
import { useInfoBox, SNAP_DISTANCE } from "../../../App";
import { distanceFormula } from "../../../utils";
import { useDefib } from "@/DefibContextManager";

export function GrayCable({
  set,
  initPos,
  padConnectorXY,
  setIsTherapyCableConnectedToPadCable,
  setPadConnectionXY,
  testPlugXY,
}: {
  set: (pos: { x: number; y: number }) => void;
  initPos: { x: number; y: number };
  padConnectorXY: { x: number; y: number };
  setIsTherapyCableConnectedToPadCable: (isConnected: boolean) => void;
  setPadConnectionXY: (pos: { x: number; y: number }) => void;
  testPlugXY: { x: number; y: number };
}) {
  const { defib } = useDefib();
  const infoBoxProps = useInfoBox({
    title: "Therapy Cable",
    description:
      "The therapy cable must be connected to its attachment port in order for the pads to work. You can use your mouse to attach the gray cable to the Quik-Combo pads once you drag off the black test plug.",
  });

  return (
    <WiredElement
      {...infoBoxProps}
      draggable={true}
      x={initPos.x}
      y={initPos.y}
      scaleX={0.5}
      scaleY={0.5}
      set={set}
      onDragMove={(e) => {
        const pos = e.target.getAbsolutePosition();
        if (distanceFormula(pos, padConnectorXY) < SNAP_DISTANCE) {
          setIsTherapyCableConnectedToPadCable(true);
          setPadConnectionXY(pos);
          defib.setTherapyCableAttachedToPadCable(true);
          defib.setIsTestPlugConnected(false);
        } else {
          defib.setTherapyCableAttachedToPadCable(false);
        }

        if (distanceFormula(pos, testPlugXY) < 10) {
          // TODO: fix distances so not janky
          defib.setIsTestPlugConnected(true);
        } else {
          defib.setIsTestPlugConnected(false);
        }
      }}
    >
      <KonvaImage src="assets/GrayCable.png" />
    </WiredElement>
  );
}
