import { Circle, Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";
import { useDefib } from "@/DefibContextManager";

export function Pacer({ isOverview }) {
  const {
    defib,
    defibState: { isPacerOn },
  } = useDefib();
  const infoBoxProps = useInfoBox({
    title: "Pacer",
    description:
      "Pressing the PACER button engages the machine's pacing functions.<i>The Lifepak's own ECG leads must be attached to the patient in order to perform demand pacing.</i>",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      x={640}
      y={340}
      onClick={() => {
        if (isOverview) return;
        defib.pacer();
      }}
    >
      <Rect
        width={85}
        height={35}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
      {isPacerOn && (
        <Circle x={16} y={18} radius={5} fill="lightgreen"></Circle>
      )}
    </ClickableGroup>
  );
}
