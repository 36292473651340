import { useDefib } from "@/DefibContextManager";
import { useEffect, useState } from "react";

export function useShowJoulesScreen() {
  const [show, setShow] = useState(false);
  const [timer, setTimer] = useState(null);
  const {
    defibState: { chargeJoules },
  } = useDefib();

  useEffect(() => {
    if (chargeJoules === 2) return;

    if (timer) clearTimeout(timer);

    setShow(true);

    const newTimer = setTimeout(() => {
      setShow(false);
    }, 2000);

    setTimer(newTimer);

    return () => clearTimeout(newTimer);
  }, [chargeJoules]);

  return show;
}
