import { KonvaImage, WiredElement } from "../../../UIComponents";
import { useInfoBox } from "../../../App";

export function PadConnected({ set, PadConnectionXY }) {
  const infoBoxProps = useInfoBox({
    title: "Quik-Combo Pad",
    description:
      " Multifunction electrodes that allow the defibrillator to deliver an electrical stimulus to the patient. Attaching the Quik-Combo pads is a multistep process. <a style='color:blue; cursor: pointer; text-decoration:underline' onclick='showMeHow2()'>Show Me How</a>",
  });
  return (
    <WiredElement
      {...infoBoxProps}
      x={PadConnectionXY.x}
      y={PadConnectionXY.y}
      set={set}
      draggable={true}
    >
      <KonvaImage scaleX={1} scaleY={1} src={"assets/ConnectedCables.png"} />
    </WiredElement>
  );
}
