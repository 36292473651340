import { Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";

export function Lead({ isOverview }) {
  const infoBoxProps = useInfoBox({
    title: "Lead",
    description:
      "Pressing the LEAD button allows you to select the ECG lead that is reading the patient's heart rhythm for display on the defibrillator monitor. Press the LEAD button repeatedly to highlight and select a particular lead.<br><a class=\"youtube\" href=\"https://www.youtube.com/watch?v=5SHkhFtLwvE\">Defibrillation without monitor display.<img class='playicon' style='vertical-align:bottom' width='30' src='assets/playicon.png'></a></div>",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      x={538}
      y={295}
      onClick={() => {
        if (isOverview) return;
      }}
    >
      <Rect
        width={38}
        height={35}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
    </ClickableGroup>
  );
}
