import { Group, Rect, Text } from "react-konva";
import { KonvaImage } from "../../../UIComponents";
import { Pulse } from "./Pulse";
import { JoulesChangeScreen } from "./JoulesChangeScreen";
import { useShowJoulesScreen } from "./useShowJoulesScreen";
import { ChargingScreen } from "./ChargingScreen";
import { TimeDisplay } from "./TimeDisplay";
import { ShockReadyScreen } from "./ShockReadyScreen";
import { HeartRateDisplay } from "./HeartRateDisplay";
import { useDefib } from "@/DefibContextManager";

export function DefibrillatorScreen({ patientHeartRhythm, patientHeartRate }) {
  const showJoulesScreen = useShowJoulesScreen();

  const {
    defibState: {
      chargeJoules,
      isTestPlugConnected,
      isCharging,
      isShockReady,
      showShockDelivered,
    },
  } = useDefib();

  return (
    <Group x={123} y={120}>
      <HeartRateDisplay patientHeartRate={patientHeartRate} />
      <Text
        text={`${chargeJoules}J`}
        x={163}
        y={3}
        fontSize={16}
        fill="yellow"
        fontStyle="bold"
      />
      <TimeDisplay />

      <Pulse patientHeartRhythm={patientHeartRhythm} />

      {isTestPlugConnected && (
        <KonvaImage
          y={163}
          x={1}
          scaleX={1.0}
          src="assets/RemoveTestPlug1.png"
        />
      )}
      {!isTestPlugConnected && !patientHeartRhythm && (
        <KonvaImage
          y={163}
          x={1}
          scaleX={1.0}
          src="assets/ConnectElectrodes1.png"
        />
      )}
      {showJoulesScreen && <JoulesChangeScreen />}
      {isCharging && <ChargingScreen />}
      {isShockReady && <ShockReadyScreen />}
      {showShockDelivered && <ShockDelivered />}
    </Group>
  );
}

function ShockDelivered() {
  return (
    <Group x={0} y={140}>
      <Rect width={360} height={130} fill="lightblue"></Rect>
      <Rect width={360} height={20} x={0} fill="blue"></Rect>
      <Rect width={360} height={20} x={0} y={110} fill="blue"></Rect>
      <Text
        width={360}
        height={20}
        y={45}
        fontSize={16}
        fill="black"
        text="Shock Delivered"
        align="center"
        fontStyle="bold"
      ></Text>
    </Group>
  );
}
