import { Circle } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";
import { useEffect, useState } from "react";
import { useDefib } from "@/DefibContextManager";

export function Shock({ isOverview }) {
  const {
    defib,
    defibState: { isShockReady },
  } = useDefib();
  const infoBoxProps = useInfoBox({
    title: "Shock",
    description:
      "Pressing the SHOCK button delivers the selected energy dose to the patient.",
  });

  const [shockLightLitUp, setShockLightLitUp] = useState(false);

  // Handle the flashing of the light
  useEffect(() => {
    let interval;
    if (isShockReady) {
      setShockLightLitUp(true); // Immediately light up
      interval = setInterval(() => {
        setShockLightLitUp((prev) => !prev);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isShockReady]);

  // Handle immediate turn off when isShockReady is false
  useEffect(() => {
    if (!isShockReady) {
      setShockLightLitUp(false);
    }
  }, [isShockReady]);

  return (
    <>
      {shockLightLitUp && (
        <Circle x={645} y={263} radius={5} fill="lightgreen"></Circle>
      )}
      <ClickableGroup
        {...infoBoxProps}
        x={684}
        y={263}
        onClick={() => {
          if (isOverview) return;
          defib.shock();
        }}
      >
        <Circle
          radius={24}
          stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
        />
      </ClickableGroup>
    </>
  );
}
