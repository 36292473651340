import { settings } from "@/settings";
import { GameState } from "./gameState";

export enum Sex {
  Male,
  Female,
}

export interface PatientParameters {
  age: number;
  alive: boolean;
  heartRate: number;
  heartRhythm: HeartRhythm;
  isCPRBeingPerformed: boolean;
  weight: number | null;
  avatar: PatientAvatar;
  sex: Sex;
  status: string;
}

export class Patient {
  age: number;
  weight: number | null;
  alive: boolean;
  heartRate: number;
  heartRhythm: HeartRhythm;
  isCPRBeingPerformed: boolean;
  avatar: PatientAvatar;
  sex: Sex;
  gameState: GameState | null;
  status: string;

  returnState: PatientParameters;

  constructor(patientParameters: PatientParameters) {
    this.age = patientParameters.age;
    this.alive = patientParameters.alive;
    this.heartRate = patientParameters.heartRate;
    this.heartRhythm = patientParameters.heartRhythm;
    this.isCPRBeingPerformed = false;
    this.weight = patientParameters.weight;
    this.avatar = patientParameters.avatar;
    this.status = patientParameters.status;
    this.sex = patientParameters.sex;
    this.gameState = null;
    this.returnState = this.calculateReturnState();
  }

  calculateReturnState(): PatientParameters {
    let retState = {
      heartRate: this.heartRate,
      heartRhythm: this.heartRhythm,
      isCPRBeingPerformed: this.isCPRBeingPerformed,
      alive: this.alive,
      age: this.age,
      weight: this.weight,
      avatar: this.avatar,
      sex: this.sex,
      status: this.status,
    };
    if (JSON.stringify(retState) !== JSON.stringify(this.returnState)) {
      this.returnState = retState;
      dispatchEvent(new Event("patientStateChange"));
    }
    return retState;
  }

  die(): void {
    console.debug("Patient died");
    this.alive = false;
    this.heartRhythm = HeartRhythm.NoPulse;
    this.heartRate = 0;
    this.calculateReturnState();
  }

  destroy(): void {
    // destroy patient
  }
  changeHeartState(heartState: {
    heartRhythm: HeartRhythm;
    heartRate: number;
  }): void {
    /// for any values provided, overwrite the patient state, but keep previous values the same for any values not provided
    this.heartRhythm = heartState.heartRhythm;
    this.heartRate = heartState.heartRate;

    this.calculateReturnState();
  }

  setGameState(gameState: any): void {
    this.gameState = gameState;
  }
  setIsCPRBeingPerformed(isCPRBeingPerformed: boolean) {
    if (isCPRBeingPerformed) {
      if (this.gameState) {
        this.gameState.tutorialStateCalculator.hasStartedCPR = true;
        this.gameState.tutorialStateCalculator.calculateReturnState();
      }
    }
    this.isCPRBeingPerformed = isCPRBeingPerformed;
    this.calculateReturnState();
    setTimeout(() => {
      if (this.heartRhythm == HeartRhythm.NormalRhythm) {
        if (this.gameState) {
          this.gameState.patientNeedsCPR = false;
        }
        this.calculateReturnState();
      }
    }, settings.WIN_TIMEOUT);
  }

  shock(joules: number): void {
    // calculate patient state based on weight, heart state, and joules

    if (this.gameState) {
      this.gameState.shockDecision(joules);
    }
    return;
  }
}

export enum HeartRhythm {
  NoPulse = 1,
  NormalRhythm,
  SlowRhythm,
  VTac,
  VFib,
  // Sync,
}

export enum PatientAvatar {
  OlderMan,
  Infant,
  Teenager,
  Todler,
}

function dispatchEvent(event: Event) {
  if (typeof window !== "undefined") {
    window.dispatchEvent(event);
  }
}
