import { Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";
import { useDefib } from "@/DefibContextManager";

export function CurrentUp({ isOverview }) {
  const { defib } = useDefib();
  const infoBoxProps = useInfoBox({
    title: "Current Up",
    description:
      "Once the PACER button is engaged and the desired RATE is selected, press the up or down arrows to adjust the pacing current until each vertical pacing spike is followed by a wide QRS complex.",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      x={683}
      y={405}
      onClick={() => {
        if (isOverview) return;
        defib.currentUp();
      }}
    >
      <Rect
        width={40}
        height={35}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
    </ClickableGroup>
  );
}
