import { useCallback, useContext, useEffect, useState } from "react";
import { Stage, Layer, Rect, Text } from "react-konva";
import { ClickableGroup } from "./UIComponents";
import { Patient } from "./model/patient";
import { GameState } from "./model/gameState";

import { InfoBoxContext } from "./InfoBoxContextManager";
import { Simulation } from "./Simulation";
import { settings } from "./settings";
import { Scene, sceneInfo } from "./scenes";
export const OVERVIEW_OUTLINE_STROKE_COLOR = "yellow";
export const SNAP_DISTANCE = 50;

import { InfoScreen } from "./InfoScreen";
import { DefibProvider, useDefib } from "./DefibContextManager";

export enum PadType {
  LEFT,
  RIGHT,
}

const App = () => {
  const [scene, setScene] = useState(Scene.TutorialCase1);

  return (
    <DefibProvider scene={scene}>
      <AppContent scene={scene} setScene={setScene} />
    </DefibProvider>
  );
};

const AppContent = ({ scene, setScene }) => {
  const [shouldRender, setShouldRender] = useState(true);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const orientation = useOrientation();

  const [showInfoDialog, setShowInfoDialog] = useState(true);

  useEffect(() => {
    const hasSeenInfoDialog = localStorage.getItem("hasSeenInfoDialog");
    if (hasSeenInfoDialog === null) {
      localStorage.setItem("hasSeenInfoDialog", "false");
      setShowInfoDialog(true);
    } else if (hasSeenInfoDialog === "false") {
      setShowInfoDialog(true);
    } else {
      setShowInfoDialog(false);
    }
  }, []);

  useEffect(() => {
    const checkSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", checkSize);
    return () => window.removeEventListener("resize", checkSize);
  }, []);

  const { defib } = useDefib();

  const [patient, patientState] = usePatient(scene);

  const [gameState, gameStateState] = useGameState(defib, patient, scene);
  if (patient && gameState && defib) {
    patient.setGameState(gameState);
    defib.setConnectedPatient(patient);
  }

  return (
    <>
      {showInfoDialog && <InfoScreen setShowInfoDialog={setShowInfoDialog} />}
      {!showInfoDialog && (
        <div style={{ backgroundColor: "white" }}>
          {orientation == "Landscape" && defib && patient && shouldRender && (
            <Stage width={windowSize.width} height={680}>
              <Layer>
                {settings.DEBUG && (
                  <DebugBar
                    setScene={setScene}
                    setShouldRender={setShouldRender}
                  />
                )}
              </Layer>

              <Simulation
                isOverview={scene == Scene.Overview}
                isTutorial={sceneInfo[scene].isTutorial}
                patientHeartRhythm={patientState.heartRhythm}
                patientAvatar={patientState.avatar}
                caseDescription={sceneInfo[scene].description}
                patientHeartRate={patientState.heartRate}
                patientStatus={patientState.status}
                patientIsCPRBeingPerformed={patientState.isCPRBeingPerformed}
                patientWeight={patientState.weight}
                patient={patient}
                gameStateState={gameStateState}
                gameState={gameState}
              />
            </Stage>
          )}
        </div>
      )}
      {orientation == "Portrait" && <RotateModal />}
    </>
  );
};

function TestCaseButton({ setScene, setShouldRender, scene, i }) {
  return (
    <ClickableGroup
      x={1000 + i * 60}
      y={76}
      onClick={() => {
        // TODO see if theres a better way to force a render
        // without using setTimeout
        setScene(scene);
        setShouldRender(false);
        setTimeout(() => {
          setShouldRender(true);
        }, 1);
      }}
    >
      <Rect width={50} height={25} fill="blue"></Rect>
      <Text text={scene} width={50} fill="white" />
    </ClickableGroup>
  );
}

function ResetButton({ setShouldRender }) {
  return (
    <ClickableGroup
      x={1200}
      y={25}
      onClick={() => {
        // TODO see if theres a better way to force a render
        // without using setTimeout
        setShouldRender(false);
        setTimeout(() => {
          setShouldRender(true);
        }, 1);

        // TODO: also need
      }}
    >
      <Rect width={50} height={25} fill="red"></Rect>
      <Text text="Reset" fill="white" />
    </ClickableGroup>
  );
}

export default App;

export function useInfoBox({ title, description }) {
  const infoBox = useContext(InfoBoxContext);

  const onMouseEnter = useCallback(() => {
    infoBox.setValues({ title, description });
  }, [infoBox.setValues, title, description]);

  const onMouseLeave = useCallback(() => {
    infoBox.setValues({ title: "", description: "" });
  }, [infoBox.setValues]);

  return {
    onMouseEnter,
    onMouseLeave,
  };
}

const usePatient = (scene) => {
  const [patient, setPatient] = useState(null);
  const [patientState, setPatientState] = useState(undefined);

  useEffect(() => {
    const p = new Patient(sceneInfo[scene].patientParams);
    setPatient(p);
    setPatientState(p.calculateReturnState());

    const handleStateChange = () => {
      console.debug("patientStateChange", p.returnState);
      setPatientState(p.returnState);
    };

    window.addEventListener("patientStateChange", handleStateChange);

    return () => {
      p.destroy();
      window.removeEventListener("patientStateChange", handleStateChange);
    };
  }, [scene]);
  return [patient, patientState];
};

function useOrientation() {
  const [orientation, setOrientation] = useState(getOrientation());

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(getOrientation());
    };
    window.addEventListener("resize", handleOrientationChange);
    return () => window.removeEventListener("resize", handleOrientationChange);
  }, []);

  return orientation;
}

function getOrientation() {
  if (window.matchMedia("(orientation: portrait)").matches) {
    return "Portrait";
  } else if (window.matchMedia("(orientation: landscape)").matches) {
    return "Landscape";
  }
  return "Unknown";
}

const useGameState = (defib, patient, scene) => {
  const [gameState, setGameState] = useState(null);
  const [gameStateState, setGameStateState] = useState({});

  useEffect(() => {
    if (!defib || !patient || !scene) {
      return;
    }

    if (!gameState || gameState.scene != scene) {
      const newGameState = new GameState(
        patient,
        defib,
        sceneInfo[scene].configuration || {}
      );
      defib.setConnectedPatient(patient);
      patient.setGameState(newGameState);
      defib.setGameState(newGameState);
      setGameStateState(newGameState.returnState);

      const handleGameStateStateChange = () => {
        setGameStateState(newGameState.returnState);
      };
      window.addEventListener(
        "gameStateStateChange",
        handleGameStateStateChange
      );

      setGameState(newGameState);
    }

    return () => {
      if (gameState) {
        gameState.destroy();
      }
    };
  }, [patient, defib, scene]);

  return [gameState, gameStateState];
};

function RotateModal() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div style={{ flex: 1 }}></div>
      <div style={{ flex: 1 }}>
        <h1>Rotate your device to landscape mode</h1>
      </div>
      <div style={{ flex: 1 }}></div>
    </div>
  );
}

function DebugBar({ setScene, setShouldRender }) {
  return (
    <>
      {Object.keys(Scene).map((s, i) => {
        return (
          <TestCaseButton
            scene={s}
            setScene={setScene}
            setShouldRender={setShouldRender}
            i={i}
            key={i}
          />
        );
      })}
      <ResetButton setShouldRender={setShouldRender} />
    </>
  );
}
