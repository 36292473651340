import { Group, Layer } from "react-konva";
import { KonvaImage } from "../../../UIComponents";
import { Cables } from "./Cables";
import { DefibrillatorButtons } from "./DefibrillatorButtons";
import { DefibrillatorScreen } from "./DefibrillatorScreen";
import { useDefib } from "@/DefibContextManager";

export function DefibrillatorComponent({
  isOverview = false,
  patientHeartRhythm,
  patientAvatar,
  patientHeartRate,
}) {
  const {
    defibState: { isOn, isTurningOn },
  } = useDefib();
  return (
    <>
      <Layer>
        <Group>
          <KonvaImage src="assets/lifepak.png" />
          {isTurningOn && (
            <KonvaImage
              scaleX={0.9}
              x={145}
              y={120}
              scaleY={0.9}
              src="assets/StartupScreen.png"
            />
          )}
          {isOn && (
            <DefibrillatorScreen
              patientHeartRhythm={patientHeartRhythm}
              patientHeartRate={patientHeartRate}
            />
          )}

          <DefibrillatorButtons isOverview={isOverview} />
        </Group>
      </Layer>
      <Layer>
        <Cables patientAvatar={patientAvatar} />
      </Layer>
    </>
  );
}
