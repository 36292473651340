import { GameState } from "./gameState";
import { Patient } from "./patient";
import { playSound, stopSound } from "./sound";

type DefibState = {
  isLeftPadConnected: boolean;
  isRightPadConnected: boolean;
  isOn: boolean;
  isLAEKGConnected: boolean;
  isRAEKGConnected: boolean;
  isLLEKGConnected: boolean;
  isSyncOn: boolean;
  isCharging: boolean;
  isShockReady: boolean;
  isTherapyCableAttachedToMachine: boolean;
  isTherapyCableAttachedToPadCable: boolean;
  isTestPlugConnected: boolean;
  isAnalyzing: boolean;
  chargeJoules: number;
  isTurningOn: boolean;
  isConnectedToPatient: boolean;
  showShockDelivered: boolean;
  isPacerOn: boolean;
};

export class Defibrillator {
  connectedPatient: Patient | null = null;

  chargeJoules: number;
  pacerRate: number;
  pacerCurrent: number;

  isLeftPadConnected: boolean;
  isRightPadConnected: boolean;
  isOn: boolean;
  isLAEKGConnected: boolean;
  isRAEKGConnected: boolean;
  isLLEKGConnected: boolean;
  isSyncOn: boolean;
  isCharging: boolean;
  isShockReady: boolean;
  isTherapyCableAttachedToMachine: boolean;
  isTherapyCableAttachedToPadCable: boolean; // the big cable that plugs right into the defib
  isTestPlugConnected: boolean;
  isAnalyzing: boolean;
  isTurningOn: boolean;
  showShockDelivered: boolean;
  isPacerOn: boolean = false;

  gameState: GameState | null;

  returnState: DefibState;

  constructor() {
    console.debug("defib init");
    this.chargeJoules = 2; // Default charge is 2J
    this.pacerRate = 100;
    this.pacerCurrent = 30;
    this.isLeftPadConnected = false;
    this.isRightPadConnected = false;
    this.isOn = false;
    this.isLAEKGConnected = false;
    this.isRAEKGConnected = false;
    this.isLLEKGConnected = false;
    this.isSyncOn = false;
    this.isCharging = false;
    this.isShockReady = false;
    this.isTherapyCableAttachedToMachine = true;
    this.isTherapyCableAttachedToPadCable = false;
    this.isTestPlugConnected = true;
    this.isAnalyzing = false;
    this.isTurningOn = false;
    this.showShockDelivered = false;
    this.gameState = null;

    this.returnState = Defibrillator.defaultReturnState();
  }

  static defaultReturnState(): DefibState {
    return {
      isLeftPadConnected: false,
      isRightPadConnected: false,
      isOn: false,
      isLAEKGConnected: false,
      isRAEKGConnected: false,
      isLLEKGConnected: false,
      isSyncOn: false,
      isCharging: false,
      isShockReady: false,
      isTherapyCableAttachedToMachine: true,
      isTherapyCableAttachedToPadCable: false,
      isTestPlugConnected: true,
      isAnalyzing: false,
      chargeJoules: 2,
      isTurningOn: false,
      isConnectedToPatient: false,
      showShockDelivered: false,
      isPacerOn: false,
    };
  }

  calculateReturnState(): DefibState {
    let retState = {
      isLeftPadConnected: this.isLeftPadConnected,
      isRightPadConnected: this.isRightPadConnected,
      isOn: this.isOn,
      isLAEKGConnected: this.isLAEKGConnected,
      isRAEKGConnected: this.isRAEKGConnected,
      isLLEKGConnected: this.isLLEKGConnected,
      isSyncOn: this.isSyncOn,
      isCharging: this.isCharging,
      isShockReady: this.isShockReady,
      isTherapyCableAttachedToMachine: this.isTherapyCableAttachedToMachine,
      isTherapyCableAttachedToPadCable: this.isTherapyCableAttachedToPadCable,
      isTestPlugConnected: this.isTestPlugConnected,
      isAnalyzing: this.isAnalyzing,
      chargeJoules: this.chargeJoules,
      isTurningOn: this.isTurningOn,
      isConnectedToPatient: this.isConnectedToPatient(),
      showShockDelivered: this.showShockDelivered,
      isPacerOn: this.isPacerOn,
    };

    if (JSON.stringify(retState) !== JSON.stringify(this.returnState)) {
      this.returnState = retState;

      dispatchEvent(new Event("defibStateChange"));
    }
    return retState;
  }

  isConnectedToPatient(): boolean {
    if (!this.isLeftPadConnected) {
      return false;
    }
    if (!this.isRightPadConnected) {
      return false;
    }
    if (!this.isTherapyCableAttachedToMachine) {
      return false;
    }
    if (!this.isTherapyCableAttachedToPadCable) {
      return false;
    }
    return true;
  }

  isEKGConnected(): boolean {
    return (
      this.isLAEKGConnected && this.isLLEKGConnected && this.isRAEKGConnected
    );
  }

  setConnectedPatient(patient: Patient | null) {
    // todo: this should be contingent on the patient being connected
    this.connectedPatient = patient;
  }

  canControlShockSettings(): boolean {
    if (this.isTestPlugConnected) {
      return true;
    }
    if (!this.isConnectedToPatient()) {
      return false;
    }
    if (this.isCharging) {
      return false;
    }
    return true;
  }
  canShock(): boolean {
    return this.canControlShockSettings() && this.isShockReady;
  }
  calculateChargeTime(): number {
    // return this.chargeJoules * 16;
    return 3000;
  }
  calculateAnalyzeTime(): number {
    return 2000;
  }

  calculateTurnOnTime(): number {
    return 1800;
  }

  setLeftPadConnected(isConnected: boolean) {
    this.isLeftPadConnected = isConnected;
    this.calculateReturnState();
  }
  setRightPadConnected(isConnected: boolean) {
    this.isRightPadConnected = isConnected;
    this.calculateReturnState();
  }
  setRAECGConnected(isConnected: boolean) {
    this.isRAEKGConnected = isConnected;
    this.calculateReturnState();
  }
  setLAECGConnected(isConnected: boolean) {
    this.isLAEKGConnected = isConnected;
    this.calculateReturnState();
  }
  setLLECGConnected(isConnected: boolean) {
    this.isLLEKGConnected = isConnected;
    this.calculateReturnState();
  }

  setTherapyCableAttachedToPadCable(isConnected: boolean) {
    console.debug("isTherapyCableAttachedToPadCableEvent");
    this.isTherapyCableAttachedToPadCable = isConnected;

    this.calculateReturnState();
  }

  setIsTestPlugConnected(isConnected: boolean) {
    if (isConnected == this.isTestPlugConnected) {
      return;
    }
    if (this.isTestPlugConnected && !isConnected) {
      if (this.gameState) {
        this.gameState.tutorialStateCalculator.hasRemovedTestPlug = true;
        this.gameState.tutorialStateCalculator.calculateReturnState();
      }
    }

    console.debug("isTestPlugConnected", isConnected);
    this.isTestPlugConnected = isConnected;

    if (this.shouldPlayConnectElectrodes()) {
      this.playConnectElectrodes();
    }
    this.calculateReturnState();
  }

  shouldPlayConnectElectrodes(): boolean {
    if (!this.isOn) {
      return false;
    }
    if (!this.isTestPlugConnected && !this.isConnectedToPatient()) {
      return true;
    }
    return false;
  }

  playConnectElectrodes() {
    playSound("connectElectrodes");
  }

  setGameState(gameState: GameState) {
    this.gameState = gameState;
  }

  onButton() {
    // TODO: Unit Tests for this.
    if (this.isTurningOn) return;
    if (this.isOn) {
      console.debug("turning sim off");
      this.isOn = false;
      this.chargeJoules = 2;
      this.calculateReturnState();
      return;
    }
    playSound("onButton");
    this.isTurningOn = true;

    if (this.gameState) {
      this.gameState.tutorialStateCalculator.hasTurnedOnDefib = true;
      this.gameState.tutorialStateCalculator.calculateReturnState();
    }

    this.calculateReturnState();
    setTimeout(() => {
      this.isTurningOn = false;
      this.isOn = true; // TODO: have light controlled by separate button

      if (this.isTestPlugConnected) {
        playSound("removeTestPlug", 500);
      }

      this.calculateReturnState();
      console.debug("turning sim on");
    }, this.calculateTurnOnTime());
  }
  energySelectDown() {
    console.debug("energySelectDown");

    if (!this.isOn) return;
    if (!this.canControlShockSettings()) return;

    playSound("buttonPress");

    const decreaseCharge = (limit: number, decrement: number) => {
      if (this.chargeJoules >= limit) {
        this.chargeJoules -= decrement;
        this.calculateReturnState();
        return true;
      }
      return false;
    };

    if (decreaseCharge(360, 35)) return;
    if (decreaseCharge(325, 25)) return;
    if (decreaseCharge(125, 25)) return;
    if (decreaseCharge(100, 30)) return;
    if (decreaseCharge(50, 20)) return;
    if (decreaseCharge(30, 10)) return;
    if (decreaseCharge(11, 5)) return;
    if (decreaseCharge(3, 1)) return;
  }
  energySelectUp() {
    console.debug("energySelectUp");
    if (!this.isOn) return;
    if (!this.canControlShockSettings()) return;

    playSound("buttonPress");

    const increaseCharge = (limit: number, increment: number) => {
      if (this.chargeJoules < limit) {
        this.chargeJoules += increment;
        this.calculateReturnState();
        return true;
      }
      return false;
    };

    if (increaseCharge(10, 1)) return;
    if (increaseCharge(20, 5)) return;
    if (increaseCharge(30, 10)) return;
    if (increaseCharge(70, 20)) return;
    if (increaseCharge(100, 30)) return;
    if (increaseCharge(325, 25)) return;
    if (increaseCharge(360, 35)) return;
  }
  charge() {
    if (!this.isOn) return;
    if (!this.canControlShockSettings()) return;

    if (this.isShockReady) return;

    this.isCharging = true;
    this.calculateReturnState();
    setTimeout(() => {
      this.isCharging = false;
      this.isShockReady = true;
      playSound("ShockReadyBeeping", 0, true);

      this.calculateReturnState();
    }, this.calculateChargeTime());
  }
  shock() {
    console.debug("shock");
    if (!this.isOn) return;
    if (!this.canControlShockSettings()) return;

    if (this.connectedPatient && this.connectedPatient.isCPRBeingPerformed) {
      return;
    }

    this.isShockReady = false;
    stopSound("ShockReadyBeeping");

    if (this.connectedPatient) {
      this.connectedPatient.shock(this.chargeJoules);
    }
    this.showShockDelivered = true;
    this.calculateReturnState();
    setTimeout(() => {
      this.showShockDelivered = false;
      this.calculateReturnState();
    }, 1000);
  }
  analyze() {
    if (!this.isOn) return;
    if (this.isTestPlugConnected) return;
    if (!this.canControlShockSettings()) return;
    if (this.isAnalyzing) return;
    this.isAnalyzing = true;
    this.chargeJoules = 200;
    this.calculateReturnState();

    playSound("AnalyzingNow");
    setTimeout(() => {
      // TODO: don't call press, use internal shared logic as the button
      // is not actually being touched.
      this.isAnalyzing = false;
      this.calculateReturnState();

      this.charge();
    }, this.calculateAnalyzeTime());
  }
  pacer() {
    console.debug("pacer");
    if (!this.isOn) return;
    if (this.isTestPlugConnected) return;
    if (!this.canControlShockSettings()) return;
    if (this.isAnalyzing) return;

    if (this.isPacerOn) {
      this.isPacerOn = false;
      this.calculateReturnState();
      return;
    }

    this.isPacerOn = true;
    this.calculateReturnState();
  }
  currentUp() {
    if (!this.isOn) return;
    if (!this.isPacerOn) return;

    if (this.pacerCurrent >= 300) return; // TODO: what is the actual max?

    this.pacerCurrent += 10;
    this.calculateReturnState();
  }

  currentDown() {
    if (!this.isOn) return;
    if (!this.isPacerOn) return;

    if (this.pacerCurrent <= 10) return; // TODO: what is the actual min?

    this.pacerCurrent -= 10;
    this.calculateReturnState();
  }

  rateUp() {
    if (!this.isOn) return;
    if (!this.isPacerOn) return;

    if (this.pacerRate >= 180) return; // TODO: what is the actual max?

    this.pacerRate += 10;
    this.calculateReturnState();
  }

  rateDown() {
    if (!this.isOn) return;
    if (!this.isPacerOn) return;

    if (this.pacerRate <= 10) return; // TODO: what is the actual min?

    this.pacerRate -= 10;
    this.calculateReturnState();
  }

  sync() {
    console.debug("sync");
    if (!this.isOn) return;
    this.isSyncOn = !this.isSyncOn;
    this.calculateReturnState();
  }

  speedDial() {
    {
      if (!this.isOn) return;

      if (this.isCharging) {
        this.isCharging = false;
      }
      if (this.isShockReady) {
        this.isShockReady = false;
        stopSound("ShockReadyBeeping");
      }
      this.calculateReturnState();
    }
  }

  destroy() {
    console.debug("destroying defib");
    if (typeof window !== "undefined") {
    }
  }
}

function dispatchEvent(event: Event) {
  if (typeof window !== "undefined") {
    window.dispatchEvent(event);
  }
}
