import { ClickableGroup, KonvaImage } from "../UIComponents";
import { useInfoBox } from "../App";
import { distanceFormula } from "../utils";
import { useDefib } from "@/DefibContextManager";

export function TestPlug({
  initPos,
  grayCableXY,
  set,
}: {
  initPos: { x: number; y: number };
  grayCableXY: { x: number; y: number };
  set: (pos: { x: number; y: number }) => void;
}) {
  const { defib } = useDefib();
  const infoBoxProps = useInfoBox({
    title: "Test Plug",
    description:
      "When the defibrillator is not in use, the test plug is typically attached to the therapy cable, and must be removed in order to connect Quick-Combo pads to the therapy cable. Use your mouse to drag the test plug away from the therapy cable.",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      draggable={true}
      scaleX={0.4}
      scaleY={0.4}
      x={initPos.x}
      y={initPos.y}
      onDragMove={(e) => {
        const pos = e.target.getAbsolutePosition();
        set({ x: pos.x, y: pos.y });
        if (distanceFormula(pos, grayCableXY) < 10) {
          // TODO: fix distances so not janky

          defib.setIsTestPlugConnected(true);
          return;
        }
        defib.setIsTestPlugConnected(false);
      }}
    >
      <KonvaImage src="assets/TestPlug.png" />
    </ClickableGroup>
  );
}
