import { Rect, Text, Group } from "react-konva";
import { ClickableGroup, KonvaImage } from "../../../UIComponents";
import { SNAP_DISTANCE, useInfoBox } from "../../../App";
import { distanceFormula } from "../../../utils";
import { useDefib } from "@/DefibContextManager";

function RAECGLead({ snapPosition, defib }) {
  const infoBoxProps = useInfoBox({
    title: "Lifepak ECG Lead",
    description:
      "To attach the Lifepak's ECG leads, drag and drop each individual lead to the designated location marker on the body. <a style='color:blue; cursor: pointer; text-decoration:underline' onclick='showMeHow()'>Show me how</a>",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      draggable={true}
      onDragMove={(e) => {
        if (
          distanceFormula(e.target.getAbsolutePosition(), snapPosition) <
          SNAP_DISTANCE
        ) {
          defib.setRAECGConnected(true);
          e.target.setAbsolutePosition({
            x: snapPosition.x - 20,
            y: snapPosition.y - 24,
          });
          return;
        }
        defib.setRAECGConnected(false);
      }}
    >
      <KonvaImage
        x={0}
        y={10}
        scaleX={0.5}
        scaleY={0.5}
        src="assets/RAEKG.png"
      />
    </ClickableGroup>
  );
}
function LLECGLead({ snapPosition, defib }) {
  const infoBoxProps = useInfoBox({
    title: "Lifepak ECG Lead",
    description:
      "To attach the Lifepak's ECG leads, drag and drop each individual lead to the designated location marker on the body. <a style='color:blue; cursor: pointer; text-decoration:underline' onclick='showMeHow()'>Show me how</a>",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      draggable={true}
      onDragMove={(e) => {
        if (
          distanceFormula(e.target.getAbsolutePosition(), snapPosition) <
          SNAP_DISTANCE
        ) {
          defib.setLLECGConnected(true);
          e.target.setAbsolutePosition({
            x: snapPosition.x - 20,
            y: snapPosition.y - 24,
          });
          return;
        }
        defib.setLLECGConnected(false);
      }}
    >
      <KonvaImage
        x={30}
        y={10}
        scaleX={0.5}
        scaleY={0.5}
        src="assets/LLEKG.png"
      />
    </ClickableGroup>
  );
}

function LAECGLead({ snapPosition, defib }) {
  const infoBoxProps = useInfoBox({
    title: "Lifepak ECG Lead",
    description:
      "To attach the Lifepak's ECG leads, drag and drop each individual lead to the designated location marker on the body. <a style='color:blue; cursor: pointer; text-decoration:underline' onclick='showMeHow()'>Show me how</a>",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      draggable={true}
      onDragMove={(e) => {
        if (
          distanceFormula(e.target.getAbsolutePosition(), snapPosition) <
          SNAP_DISTANCE
        ) {
          defib.setLAECGConnected(true);
          e.target.setAbsolutePosition({
            x: snapPosition.x - 20,
            y: snapPosition.y - 24,
          });
          return;
        }
        defib.setLAECGConnected(false);
      }}
    >
      <KonvaImage
        x={60}
        y={10}
        scaleX={0.5}
        scaleY={0.5}
        src="assets/LAEKG.png"
      />
    </ClickableGroup>
  );
}

export function ECGLeads({ snapPositions }) {
  const { defib } = useDefib();
  return (
    <>
      <Group x={850} y={80}>
        <Rect stroke="blue" width={100} height={80} />
        <Text
          fill="black"
          width={120}
          height={80}
          x={0}
          y={-15}
          text="Lifepak's ECG Leads"
        />
        <RAECGLead snapPosition={snapPositions.RAECG} defib={defib} />
        <LLECGLead snapPosition={snapPositions.LLECG} defib={defib} />
        <LAECGLead snapPosition={snapPositions.LAECG} defib={defib} />
      </Group>
    </>
  );
}
