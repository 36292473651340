import { Text } from "react-konva";
import { KonvaImage } from "../../../UIComponents";
import { Heart } from "./Heart";

export function HeartRateDisplay({ patientHeartRate }) {
  const patientHeartRateText =
    patientHeartRate != null ? patientHeartRate : "---";

  return (
    <>
      <Text
        text={`HR ${patientHeartRateText}`}
        fill="lightblue"
        fontStyle="bold"
        fontSize={20}
      />

      <KonvaImage x={340} src="assets/Battery.png" />
      {patientHeartRate !== null && <Heart />}

      <KonvaImage y={33} src="assets/PaddlesX1.png" />
    </>
  );
}
