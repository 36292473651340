import { KonvaImage, WiredElement } from "../../../UIComponents";
import { useInfoBox, SNAP_DISTANCE } from "../../../App";
import { distanceFormula } from "../../../utils";
import { useDefib } from "@/DefibContextManager";

export function PadConnector({
  set,
  grayCableXY,
  setIsTherapyCableConnectedToPadCable,
  setPadConnectionXY,
}) {
  const { defib } = useDefib();
  const infoBoxProps = useInfoBox({
    title: "Pad Connector",
    description:
      " Multifunction electrodes that allow the defibrillator to deliver an electrical stimulus to the patient. Attaching the Quik-Combo pads is a multistep process. <a style='color:blue; cursor: pointer; text-decoration:underline' onclick='showMeHow2()'>Show Me How</a>",
  });
  return (
    <WiredElement
      {...infoBoxProps}
      x={900}
      y={600}
      set={set}
      onDragMove={(e) => {
        const pos = e.target.getAbsolutePosition();
        if (distanceFormula(pos, grayCableXY) < SNAP_DISTANCE) {
          setIsTherapyCableConnectedToPadCable(true);
          setPadConnectionXY(pos);
          defib.setTherapyCableAttachedToPadCable(true);
          defib.setIsTestPlugConnected(false);

          return;
        }
        return;
      }}
      draggable={true}
    >
      <KonvaImage scaleX={0.6} scaleY={0.6} src={"assets/WhiteCable.png"} />
    </WiredElement>
  );
}
