import { useState } from "react";
import { Line } from "react-konva";
import { Pad } from "./Pad";
import { TherapyCable } from "./TherapyCable";
import { ECGCable } from "./ECGCable";
import { GrayCable } from "./GrayCable";
import { PadConnector } from "./PadConnector";
import { PadType } from "../../../App";
import { PadConnected } from "./PadConnected";
import { ECGLeads } from "./ECGLeads";
import { TestPlug } from "../../TestPlug";
import { ConnectorType, snapPositions } from "../../../Patient";

export function Cables({ patientAvatar }) {
  const snapPositionsFromAvatar = snapPositions(patientAvatar);

  const [leftPadXY, setLeftPadXY] = useState({
    x: 950,
    y: 550,
  });

  const [rightPadXY, setRightPadXY] = useState({
    x: 1000,
    y: 550,
  });
  const [padConnectorXY, setpadConnectorXY] = useState({
    x: 900,
    y: 600,
  });

  const [therapyCableXY, setTherapyCableXY] = useState({
    x: 530,
    y: 550,
  });

  const [grayCableXY, setGrayCableXY] = useState({
    x: 830,
    y: 550,
  });

  const [padConnectionXY, setPadConnectionXY] = useState({
    x: 830,
    y: 550,
  });

  const [testPlugXY, setTestPlugXY] = useState({
    x: 870,
    y: 555,
  });

  const [
    isTherapyCableConnectedToPadCable,
    setIsTherapyCableConnectedToPadCable,
  ] = useState(false);

  return (
    <>
      <Pad
        padType={PadType.LEFT}
        set={setLeftPadXY}
        padPosition={leftPadXY}
        snapPosition={snapPositionsFromAvatar[ConnectorType.LeftPad]}
      />
      <Pad
        padPosition={rightPadXY}
        padType={PadType.RIGHT}
        set={setRightPadXY}
        snapPosition={snapPositionsFromAvatar[ConnectorType.RightPad]}
      />
      <ECGCable />
      <TherapyCable set={setTherapyCableXY} />
      <ECGLeads snapPositions={snapPositionsFromAvatar} />
      {!isTherapyCableConnectedToPadCable && (
        <>
          <TestPlug
            grayCableXY={grayCableXY}
            initPos={testPlugXY}
            set={setTestPlugXY}
          />
          <PadConnector
            set={setpadConnectorXY}
            grayCableXY={grayCableXY}
            setIsTherapyCableConnectedToPadCable={
              setIsTherapyCableConnectedToPadCable
            }
            setPadConnectionXY={setPadConnectionXY}
          />
          <GrayCable
            set={setGrayCableXY}
            initPos={grayCableXY}
            padConnectorXY={padConnectorXY}
            setIsTherapyCableConnectedToPadCable={
              setIsTherapyCableConnectedToPadCable
            }
            setPadConnectionXY={setPadConnectionXY}
            testPlugXY={testPlugXY}
          />
          <Line
            points={[
              padConnectorXY.x + 58,
              padConnectorXY.y + 22,
              padConnectorXY.x + 100,
              padConnectorXY.y,
              leftPadXY.x + 21,
              leftPadXY.y + 47,
            ]}
            tension={0.5}
            stroke="black"
          ></Line>
          <Line
            points={[
              therapyCableXY.x + 265,
              therapyCableXY.y + 70,
              therapyCableXY.x + 365,
              therapyCableXY.y + 70,
              grayCableXY.x,
              grayCableXY.y + 20,
            ]}
            tension={0.5}
            stroke="black"
          ></Line>

          <Line
            points={[
              padConnectorXY.x + 58,
              padConnectorXY.y + 22,
              padConnectorXY.x + 155,
              padConnectorXY.y + 14,
              rightPadXY.x + 21,
              rightPadXY.y + 47,
            ]}
            tension={0.5}
            stroke="black"
          ></Line>
        </>
      )}
      {isTherapyCableConnectedToPadCable && (
        <>
          <PadConnected
            PadConnectionXY={padConnectionXY}
            set={setPadConnectionXY}
          />
          <Line
            points={[
              padConnectionXY.x + 70,
              padConnectionXY.y + 15,
              padConnectionXY.x + 170,
              padConnectionXY.y,
              leftPadXY.x + 21,
              leftPadXY.y + 47,
            ]}
            tension={0.5}
            stroke="black"
          ></Line>
          <Line
            points={[
              therapyCableXY.x + 265,
              therapyCableXY.y + 70,
              therapyCableXY.x + 365,
              therapyCableXY.y + 70,
              padConnectionXY.x,
              padConnectionXY.y + 15,
            ]}
            tension={0.5}
            stroke="black"
          ></Line>

          <Line
            points={[
              padConnectionXY.x + 70,
              padConnectionXY.y + 15,
              padConnectionXY.x + 170,
              padConnectionXY.y,
              rightPadXY.x + 21,
              rightPadXY.y + 47,
            ]}
            tension={0.5}
            stroke="black"
          ></Line>
        </>
      )}
    </>
  );
}
