import { Rect } from "react-konva";
import { ClickableGroup, KonvaImage } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";
import { useDefib } from "@/DefibContextManager";

export function Analyze({ isOverview }) {
  const {
    defib,
    defibState: { isAnalyzing },
  } = useDefib();
  // TODO: AED MODE
  const infoBoxProps = useInfoBox({
    title: "Analyze",
    description:
      "Pressing ANALYZE activates the machine's AED mode. To exit AED mode, press either the ENERGY SELECT button, or the LEAD button.<br><br> Once you press ANALYZE, the machine will issue audible prompts. In AED mode on the Lifepak, the default energy dose is 200J. <a class='youtube' href='https://www.youtube.com/watch?v=3a41lR1W1JI'>More on AED Mode <img class='playicon' style='vertical-align:bottom'  width='30' src='assets/playicon.png'></a>",
  });
  return (
    <ClickableGroup
      {...infoBoxProps}
      x={538}
      y={245}
      onClick={() => {
        if (isOverview) return;
        defib.analyze();
      }}
    >
      {isAnalyzing && <KonvaImage src="assets/Light.png" x={11} y={13} />}
      <Rect
        width={85}
        height={35}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
    </ClickableGroup>
  );
}
