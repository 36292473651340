import { settings } from "../settings";

const SOUND_REGISTRY = {
  onButton: "assets/audio/on_sound.mp3",
  buttonPress: "assets/audio/tick.mp3",
  removeTestPlug: "assets/audio/removetestplug.mp3",
  connectElectrodes: "assets/audio/connectelectrodes.mp3",
  AnalyzingNow: "assets/audio/analyzingnowstandclear.mp3",
  ShockReadyBeeping: "assets/audio/shockprompt.mp3",
};

type SoundName = keyof typeof SOUND_REGISTRY;

const currentlyPlaying = {};
export function playSound(sound: SoundName, delay = 0, loop = false) {
  if (!settings.SOUND_ON) return;
  if (delay) {
    setTimeout(() => playSound(sound), delay);
    return;
  }
  console.debug(`Playing ${sound}`);
  const asset = SOUND_REGISTRY[sound];
  const audio = new Audio(asset);
  audio.loop = loop;
  if (loop) {
    currentlyPlaying[sound] = audio;
  }
  audio.play();
}

export function stopSound(sound: SoundName) {
  if (!settings.SOUND_ON) return;

  // TODO: refactor this so that stopSound is called
  // when state is updated
  const audio = currentlyPlaying[sound];
  audio.pause();
}
