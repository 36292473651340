import { Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";
import { useDefib } from "@/DefibContextManager";

export function Charge({ isOverview }) {
  const { defib } = useDefib();
  const infoBoxProps = useInfoBox({
    title: "Charge",
    description:
      "Pressing the CHARGE button charges the machine to the selected energy dose. You must press CHARGE first before delivering a shock.",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      x={640}
      y={200}
      onClick={() => {
        if (isOverview) return;
        defib.charge();
      }}
    >
      <Rect
        width={85}
        height={35}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
    </ClickableGroup>
  );
}
