import { useDefib } from "@/DefibContextManager";
import { Group, Rect, Text } from "react-konva";

export function JoulesChangeScreen() {
  const {
    defibState: { chargeJoules },
  } = useDefib();
  return (
    <Group x={0} y={140}>
      <Rect width={360} height={130} fill="lightblue"></Rect>
      <Rect width={360} height={20} x={0} fill="blue"></Rect>
      <Rect width={360} height={20} x={0} y={110} fill="blue"></Rect>
      <Text
        width={360}
        height={20}
        y={30}
        fontSize={16}
        fill="black"
        align="center"
        fontStyle="bold"
        text="Select Biphasic Energy"
      ></Text>
      <Text
        width={360}
        height={20}
        y={60}
        align="center"
        fontStyle="bold"
        fill="black"
        fontSize={16}
        text={`${chargeJoules}J`}
      ></Text>
    </Group>
  );
}
