import { Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";
import { useDefib } from "@/DefibContextManager";

export function EnergySelectUp({ isOverview }) {
  const { defib } = useDefib();
  const infoBoxProps = useInfoBox({
    title: "Energy Select Up",
    description:
      "This control allows the user to select the desired energy dose by pushing the up or down arrows.",
  });

  return (
    <ClickableGroup
      {...infoBoxProps}
      x={685}
      y={166}
      onClick={() => {
        if (isOverview) return;
        defib.energySelectUp();
      }}
    >
      <Rect
        width={45}
        height={35}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
    </ClickableGroup>
  );
}
