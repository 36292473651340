import { useDefib } from "@/DefibContextManager";
import { KonvaImage } from "@/UIComponents";
import { Group, Rect, Text } from "react-konva";

export function ShockReadyScreen() {
  const {
    defibState: { chargeJoules },
  } = useDefib();
  return (
    <Group x={0} y={140}>
      <Rect width={360} height={130} fill="lightblue"></Rect>
      <Rect width={360} height={20} x={0} fill="blue"></Rect>
      <Rect width={360} height={20} x={0} y={110} fill="blue"></Rect>
      <Text
        width={360}
        height={20}
        y={20}
        align="center"
        fontStyle="bold"
        fill="black"
        fontSize={16}
        text={`${chargeJoules}J Available`}
      ></Text>
      <Text
        width={360}
        height={20}
        y={55}
        x={100}
        fontStyle="bold"
        fill="black"
        fontSize={16}
        text="Push"
      ></Text>
      <KonvaImage
        x={150}
        y={40}
        scaleX={0.8}
        scaleY={0.8}
        src="assets/Shock.png"
      />
      <Text
        width={360}
        height={20}
        y={55}
        x={200}
        fontStyle="bold"
        fill="black"
        fontSize={16}
        text="Button!"
      ></Text>
      <Text
        width={360}
        height={20}
        y={90}
        fontSize={16}
        fill="black"
        text={"Push Speed Dial to Disarm"}
        align="center"
        fontStyle="bold"
      ></Text>
    </Group>
  );
}
