import { Size } from "./Size";
import { Alarms } from "./Alarms";
import { Options } from "./Options";
import { Event } from "./Event";
import { HomeScreen } from "./HomeScreen";
import { CodeSummary } from "./CodeSummary";
import { Print } from "./Print";

export function OverviewOnly() {
  return (
    <>
      <Event />
      <HomeScreen />
      <CodeSummary />
      <Print />
      <Alarms />
      <Options />
      <Size />
    </>
  );
}
