import { Rect } from "react-konva";
import { ClickableGroup } from "../../../UIComponents";
import { OVERVIEW_OUTLINE_STROKE_COLOR, useInfoBox } from "../../../App";
import { useDefib } from "@/DefibContextManager";

export function SpeedDial({ isOverview }) {
  const infoBoxProps = useInfoBox({
    title: "Speed Dial",
    description:
      'The Speed Dial can be used to scroll through and select desired energy doses, pacer rate, pacer current settings, or cardiac leads. In clinical practice you turn the dial to scroll, and push to select. On this simulator, you will not be able to "scroll" using the Speed Dial, but you can "push" it by clicking on it with your mouse or touchpad. Pushing the Speed Dial button during the defibrillator\'s charging process will interrupt the charge and disarm the machine. ',
  });
  const { defib } = useDefib();

  return (
    <ClickableGroup
      {...infoBoxProps}
      x={362}
      y={535}
      onClick={() => {
        if (isOverview) return;
        defib.speedDial();
      }}
    >
      <Rect
        width={60}
        height={60}
        stroke={(isOverview && OVERVIEW_OUTLINE_STROKE_COLOR) || null}
      />
    </ClickableGroup>
  );
}
